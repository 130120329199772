import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import { Container } from "react-bootstrap";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./screens/Home";
import Product from "./screens/Product";
import About from "./screens/About";
import Feedback from "./screens/LeaveFeedback";
import Bag from "./screens/Bag";
import Checkout from "./screens/Checkout";
import OrderConfirmation from "./screens/OrderConfirmation";
import FeedbackConfirmation from "./screens/FeedbackConfirmation";

// Gifting Screens
// Send
import SendAGift from "./screens/gifting/send/SendAGift";

// Redeem
import RedeemEGift from "./screens/gifting/redeem/RedeemEGift";
import RedeemEGiftMessage from "./screens/gifting/redeem/RedeemEGiftMessage";
import FAQs from "./screens/footer/FAQs";
import InTheNews from "./screens/footer/InTheNews";
import Press from "./screens/footer/Press";
import PartnershipsAndCollabs from "./screens/footer/PartnershipsAndCollabs";
import MarketingSignup from "./screens/MarketingSignup";

const App = () => {
  return (
    <div id="main">
      <Router>
        <ScrollToTop />
        <Header />
        <main className="py-3" style={{ overflow: "hidden" }}>
          <Container>
            <Switch>
              {/* Indexed */}
              <Route path="/" component={Home} exact />
              {/* Indexed */}
              <Route path="/product" component={Product} exact />
              {/* Indexed */}
              <Route path="/about" component={About} exact />
              {/* Indexed */}
              <Route path="/feedback" component={Feedback} exact />
              <Route
                path="/feedback-confirmation"
                component={FeedbackConfirmation}
                exact
              />
              <Route path="/bag" component={Bag} exact />
              <Route path="/checkout" component={Checkout} exact />
              <Route
                path="/order-confirmation"
                component={OrderConfirmation}
                exact
              />

              {/* Gifting */}
              {/* Send */}
              {/* Indexed */}
              <Route path="/send-a-gift" component={SendAGift} exact />
              {/* Redeem */}
              <Route path="/redeem-e-gift/:id" component={RedeemEGift} exact />
              <Route
                path="/redeem-e-gift/message/:id"
                component={RedeemEGiftMessage}
                exact
              />

              {/* For footer */}
              <Route path="/faqs" component={FAQs} exact />
              <Route path="/in-the-news" component={InTheNews} exact />
              <Route path="/press" component={Press} exact />
              <Route
                path="/partnerships-and-collabs"
                component={PartnershipsAndCollabs}
                exact
              />
              {/* Marketing */}
              <Route path="/signup" component={MarketingSignup} exact />
            </Switch>
          </Container>
        </main>
        <Footer />
      </Router>
    </div>
  );
};

export default App;
