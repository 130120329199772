import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import { Carousel, Col, Container, Row } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import HeroSection from "../components/HeroSection";
import HeroSectionVTwo from "../components/HeroSectionVTwo";
import Testimonials from "../components/Testimonials";
// comment
import fitImg from "../assets/images/brand-product-icons/fit.webp";
import functionalImg from "../assets/images/brand-product-icons/functional.webp";
import designImg from "../assets/images/brand-product-icons/design.webp";

import buyImage from "../assets/images/gifting-explanation/buy.webp";
import giftImage from "../assets/images/gifting-explanation/gift.webp";
import selectsImage from "../assets/images/gifting-explanation/selects.webp";

import detailsImg0 from "../assets/images/homepage-details/bra_details1.webp";
import detailsImg1 from "../assets/images/homepage-details/KBM04376.webp";
import detailsImg2 from "../assets/images/homepage-details/bra_details2.webp";
import detailsImg3 from "../assets/images/homepage-details/bra_details3.webp";
import detailsImg4 from "../assets/images/homepage-details/bra_details4.webp";
import detailsImg5 from "../assets/images/homepage-details/KBM04494.webp";
import detailsGroupImg from "../assets/images/homepage-details/group.webp";
import detailsGroupImg2 from "../assets/images/homepage-details/group2.webp";

const Home = () => {
  const history = useHistory();

  useEffect(() => {
    document.title = "The Best Bra for Sensitive Skin";
  }, []);

  return (
    <div style={{ fontSize: "1.5rem" }}>
      <Helmet>
        <title>The Best Bra for Sensitive Skin</title>
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content="The sensitive skin friendly bra with a luxurious feel and functional for all sensitive needs from post-surgery, and mastectomies and radiotherapy. Provides support without the irritation."
        />
        <meta
          property="og:image:secure_url"
          content="https://www.givereyz.com/static/media/HeroImagePrimaryBlackFront.5a3e485f.webp"
        />
        <meta property="og:image:alt" content="E-Card Image" />
        <meta property="og:image:type" content="image/webp" />
      </Helmet>
      {/* <HeroSection /> */}
      <HeroSectionVTwo />

      {/* Product Benefits 1 */}
      <section
        style={{
          textAlign: "center",
          paddingTop: "32px",
          paddingBottom: "32px",
        }}
      >
        <Container>
          <Row>
            <h2 style={{ fontSize: "2em" }}>The Reyz Difference</h2>
          </Row>
          <Row>
            <Col xl={4} md={4} sm={4}>
              <Image
                src={fitImg}
                roundedCircle
                style={{ width: 96, height: 96, margin: "4px" }}
                alt="fit"
                loading="lazy"
              />
              <h2>Fit</h2>
              <p>comfortable, wide shoulder, smooth silhouette</p>
            </Col>
            <Col xl={4} md={4} sm={4}>
              <Image
                src={functionalImg}
                roundedCircle
                style={{ width: 96, height: 96, margin: "4px" }}
                alt="functional"
                loading="lazy"
              />
              <h2>Functional</h2>
              <p>skin-friendly fabric supports ice packs</p>
            </Col>
            <Col xl={4} md={4} sm={4}>
              <Image
                src={designImg}
                roundedCircle
                style={{ width: 96, height: 96, margin: "4px" }}
                alt="design"
                loading="lazy"
              />
              <h2>Design</h2>
              <p>single layer, quality yarn, seamless knit</p>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Product Benefits 2 */}
      <section
        style={{
          paddingBottom: "56px",
        }}
      >
        <Container style={{ textAlign: "center" }}>
          <Row>
            <h2 style={{ fontSize: "1.75em" }}>
              The Difference is in the Details
            </h2>
          </Row>
          <Row>
            <Col
              xl={{ span: 6, order: 2 }}
              md={{ span: 6, order: 2 }}
              sm={{ span: 4, order: 1 }}
              style={{ marginBottom: "16px" }}
            >
              <Carousel
                indicators={false}
                style={{
                  // height: "55vh",
                  marginTop: "16px",
                  overflow: "hidden",
                }}
              >
                <Carousel.Item>
                  <img
                    src={detailsGroupImg}
                    alt="details"
                    style={{ maxWidth: "100%", maxHeight: "100%" }}
                    loading="lazy"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    src={detailsGroupImg2}
                    alt="details"
                    style={{ maxWidth: "100%", maxHeight: "100%" }}
                    loading="lazy"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    src={detailsImg0}
                    alt="details"
                    style={{ maxWidth: "100%", maxHeight: "100%" }}
                    loading="lazy"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    src={detailsImg1}
                    alt="details"
                    style={{ maxWidth: "100%", maxHeight: "100%" }}
                    loading="lazy"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    src={detailsImg2}
                    alt="details"
                    style={{ maxWidth: "100%", maxHeight: "100%" }}
                    loading="lazy"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    src={detailsImg3}
                    alt="details"
                    style={{ maxWidth: "100%", maxHeight: "100%" }}
                    loading="lazy"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    src={detailsImg4}
                    alt="details"
                    style={{ maxWidth: "100%", maxHeight: "100%" }}
                    loading="lazy"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    src={detailsImg5}
                    alt="details"
                    style={{ maxWidth: "100%", maxHeight: "100%" }}
                    loading="lazy"
                  />
                </Carousel.Item>
              </Carousel>
            </Col>
            <Col
              xl={{ span: 6, order: 1 }}
              md={{ span: 6, order: 1 }}
              sm={{ span: 4, order: 2 }}
            >
              <p>
                Optimal breast health can look different for everyone. Reyz is
                specially designed to avoid bacteria, chafing, and discomfort.
                <br />
                <br />
                Knitted construction minimizes moisture, skin-on-skin contact,
                and band roll up.
                <br />
                <br />
                Features a one piece, step-in, wire-free, design with higher cut
                necklines and underarms.
                <br />
                <br />
                All breast health and recovery require a reliable, comfortable,
                and supportive bra. This is it.
              </p>
            </Col>
          </Row>
          <Row>
            <Col
              xl={{ span: 4, offset: 4 }}
              md={{ span: 4, offset: 4 }}
              sm={{ span: 4 }}
            >
              <Button
                className="w-100"
                onClick={() => {
                  history.push("/product");
                }}
              >
                Shop Now
              </Button>
            </Col>
          </Row>
        </Container>
      </section>

      {/* How Gifting Works */}
      <section
        style={{
          paddingBottom: "32px",
        }}
      >
        <Container style={{ textAlign: "center" }}>
          <Row>
            <h2 style={{ fontSize: "1.75em" }}>Give Reyz, Give Relief</h2>
            <p style={{ marginBottom: "0px" }}>
              Support friends and family with the gift of Reyz.
            </p>
            <p>One less worry, one warm heart.</p>
          </Row>
          <Row>
            <Col xl={4} md={4} sm={{ span: 4, order: 0 }}>
              <Image
                src={buyImage}
                style={{
                  width: 312,
                  height: 415,
                  marginBottom: "12px",
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
                loading="lazy"
                fluid
              />
              <h3 style={{ fontSize: "1.5em" }}>You Buy</h3>
              <p style={{ marginBottom: "40px" }}>
                Select e-card and complete the purchase (recipient address not
                needed).
              </p>
            </Col>
            <Col xl={4} md={4} sm={{ span: 4, order: 1 }}>
              <Image
                src={selectsImage}
                style={{
                  width: 312,
                  height: 415,
                  marginBottom: "12px",
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
                fluid
                loading="lazy"
              />
              <h3 style={{ fontSize: "1.5em" }}>She Selects</h3>
              <p style={{ marginBottom: "40px" }}>
                She receives email or text with card and code, selects her
                products.
              </p>
            </Col>
            <Col xl={4} md={4} sm={{ span: 4, order: 2 }}>
              <Image
                src={giftImage}
                style={{
                  width: 312,
                  height: 415,
                  marginBottom: "12px",
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
                fluid
                loading="lazy"
              />
              <h3 style={{ fontSize: "1.5em" }}>We Deliver</h3>
              <p style={{ marginBottom: "40px" }}>
                Curated gift package delivered with care.
              </p>
            </Col>
          </Row>
          <Row>
            <Col
              xl={{ span: 4, offset: 4 }}
              md={{ span: 4, offset: 4 }}
              sm={{ span: 4, offset: 0 }}
            >
              <div className="d-grid col-12">
                <Button
                  className="w-100"
                  onClick={() => {
                    history.push("/send-a-gift");
                  }}
                >
                  Send a Gift
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Testimonials */}
      <div style={{ marginTop: "16px" }}>
        <Testimonials color="#fff" />
        <div style={{ height: "2px" }} />
      </div>
    </div>
  );
};

export default Home;
