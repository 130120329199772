import React from "react";
import { Modal } from "react-bootstrap";
import { Button, Col, Container, Row, Image } from "react-bootstrap";

import cloudsButterFly from "../../assets/images/cards/clouds_butterfly.webp";
import cloudsGetThrough from "../../assets/images/cards/clouds_getthrough.webp";
import cloudsInspire from "../../assets/images/cards/clouds_inspire.webp";
import purpleAwesome from "../../assets/images/cards/purple_awesome.webp";
import purpleGetThrough from "../../assets/images/cards/purple_getthrough.webp";
import purpleLoved from "../../assets/images/cards/purple_loved.webp";
import merryChristmas from "../../assets/images/cards/merry-christmas.webp";
import happyHolidays from "../../assets/images/cards/happy-holidays.webp";
import happyNewYear from "../../assets/images/cards/happy-new-year.webp";

const SelectECardModal = (props) => {
  const onCardSelected = (e) => {
    props.setGiftDetails({
      ...props.giftDetails,
      eCardSrc: e.target.src,
    });
  };

  const onContinue = () => {
    props.setShow(false);
  };

  return (
    <>
      <Modal
        show={props.show}
        onHide={() => props.setShow(false)}
        dialogClassName="modal-90w"
        aria-labelledby="size-guide-title"
        scrollable={true}
      >
        <Modal.Header closeButton={false}>
          <Modal.Title id="size-guide-title">Select an E-Card</Modal.Title>

          <Button className="col-sm-6 col-lg-2" onClick={onContinue}>
            Continue
          </Button>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col>
                <div className="e-cards">
                  <Container>
                    <Row>
                      <Col>
                        <label>
                          <input type="radio" name="card" value="" />
                          <Image
                            className="e-card-image img-fluid"
                            src={merryChristmas}
                            onClick={onCardSelected}
                          />
                        </label>
                        <label>
                          <input type="radio" name="card" value="" />
                          <Image
                            className="e-card-image img-fluid"
                            src={happyHolidays}
                            onClick={onCardSelected}
                          />
                        </label>
                        <label>
                          <input type="radio" name="card" value="" />
                          <Image
                            className="e-card-image img-fluid"
                            src={happyNewYear}
                            onClick={onCardSelected}
                          />
                        </label>
                        <label>
                          <input type="radio" name="card" value="" />
                          <Image
                            className="e-card-image img-fluid"
                            src={cloudsButterFly}
                            onClick={onCardSelected}
                          />
                        </label>
                        <label>
                          <input type="radio" name="card" value="" />
                          <Image
                            className="e-card-image img-fluid"
                            src={cloudsGetThrough}
                            onClick={onCardSelected}
                          />
                        </label>
                        <label>
                          <input type="radio" name="card" value="" />
                          <Image
                            className="e-card-image img-fluid"
                            src={cloudsInspire}
                            onClick={onCardSelected}
                          />
                        </label>
                        <label>
                          <input type="radio" name="card" value="" />
                          <Image
                            className="e-card-image img-fluid"
                            src={purpleAwesome}
                            onClick={onCardSelected}
                          />
                        </label>
                        <label>
                          <input type="radio" name="card" value="" />
                          <Image
                            className="e-card-image img-fluid"
                            src={purpleGetThrough}
                            onClick={onCardSelected}
                          />
                        </label>
                        <label>
                          <input type="radio" name="card" value="" />
                          <Image
                            className="e-card-image img-fluid"
                            src={purpleLoved}
                            onClick={onCardSelected}
                          />
                        </label>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SelectECardModal;
