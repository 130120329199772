import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import Alert from "react-bootstrap/Alert";
import { connect } from "react-redux";
import { setBagToEmptyState } from "../redux";
import axios from "axios";
import { Row, Col, Modal, Spinner } from "react-bootstrap";
import Toast from "react-bootstrap/Toast";
import EmailCard from "../components/checkout/EmailCard";
import ShippingCard from "../components/checkout/ShippingCard";
import PaymentCard from "../components/checkout/PaymentCard";
import OrderSummary from "../components/checkout/OrderSummary";
import { saveBagShippingInfo } from "../redux/bag/bagActions";
import { addRedemptionLinks } from "../redux/redeemLinks/redeemLinksActions";
import emailjs from "emailjs-com";

// Todo: learn how to use REACT_APP env vars in heroku
const ENV = "development";

const Checkout = (props) => {
  const giftItemIdsLoaded = useRef(false);
  const [giftItemIds, setGiftItemIds] = useState({
    ZOHO_GB_ID: "",
    ZOHO_WB_ID: "",
  });

  useEffect(() => {
    document.title = "Checkout";
  }, []);

  useEffect(() => {
    if (!giftItemIdsLoaded.current) {
      const getGiftItemIds = async () => {
        const getGBid = await axios.get("/api/gifting/getGBid");
        const getWBid = await axios.get("/api/gifting/getWBid");
        const ZOHO_GB_ID = getGBid.data.ZOHO_GB_ID;
        const ZOHO_WB_ID = getWBid.data.ZOHO_WB_ID;

        setGiftItemIds({
          ZOHO_GB_ID: ZOHO_GB_ID,
          ZOHO_WB_ID: ZOHO_WB_ID,
        });

        giftItemIdsLoaded.current = true;
      };

      getGiftItemIds();
    }
  }, []);

  const bagShippingInfo = props.bagShippingInfo;
  const [useShipping, setUseShipping] = useState(false);
  const [show, setShow] = useState(false);
  const [paypalItems, setPaypalItems] = useState([]);
  const [errorAlert, setErrorAlert] = useState({ show: false, message: "" });

  const [showOrderIsProcessing, setShowOrderIsProcessing] = useState(false);

  const [shippingInfo, setShippingInfo] = useState({
    firstName: bagShippingInfo.firstName,
    lastName: bagShippingInfo.lastName,
    addressOne: bagShippingInfo.addressOne,
    addressTwo: bagShippingInfo.addressTwo,
    country: "US",
    city: bagShippingInfo.city,
    state: bagShippingInfo.state,
    zip: bagShippingInfo.zip,
  });

  const [checkoutProcess, setCheckoutProcess] = useState({
    editEmail: true,
    emailSubmitted: false,
    editShippingInfo: false,
    shippingInfoSubmitted: false,
  });

  const [payPalSdkReady, setPayPalSdkReady] = useState(false);

  const bagItems = props.bagItems;
  const promoApplied = props.promoApplied;

  const addDecimals = (num) => {
    return (Math.round(num * 100) / 100).toFixed(2);
  };

  const bagItemsPrice = addDecimals(
    (
      bagItems.directPurchases.reduce((acc, item) => acc + item.braPrice, 0) +
      bagItems.giftPackages.reduce((acc, item) => acc + item.packagePrice, 0)
    ).toFixed(2)
  );

  let bagShippingPrice;
  if (bagItemsPrice == 0.0) {
    bagShippingPrice = addDecimals(0);
  } else {
    bagShippingPrice = addDecimals(8.75);
  }

  const bagTaxPrice = addDecimals(Number((0).toFixed(2)));
  const bagTotalPrice = addDecimals(
    Number(bagItemsPrice) + Number(bagShippingPrice) + Number(bagTaxPrice)
  );

  const [prices, setPrices] = useState({
    bagItemsPrice: bagItemsPrice,
    bagShippingPrice: bagShippingPrice,
    bagTaxPrice: bagTaxPrice,
    bagTotalPrice: bagTotalPrice,
  });

  const compileTaxInfo = (gift) => {
    let address = null;
    if (shippingInfo.addressTwo === "" || shippingInfo.addressTwo == null) {
      address = shippingInfo.addressOne;
    } else {
      address = `${shippingInfo.addressOne} ${shippingInfo.addressTwo}`;
    }

    const avalaraItems = [];

    avalaraItems.push({
      quantity: 1,
      amount: prices.bagShippingPrice,
      taxCode: "FR",
      itemCode: "Shipping",
    });

    // create lines for direct purchases
    for (let i = 0; i < bagItems.directPurchases.length; i++) {
      avalaraItems.push({
        quantity: 1,
        amount: bagItems.directPurchases[i].braPrice,
        taxCode: "PC040152",
        itemCode: "2876633000000075299",
        description: "Bra Purchase",
      });
    }

    // create lines for gift packages
    for (let i = 0; i < bagItems.giftPackages.length; i++) {
      for (let j = 0; j < bagItems.giftPackages[i].items.length; j++) {
        // gift package lines: gift box
        const item = bagItems.giftPackages[i].items[j];

        if (item.id === giftItemIds.ZOHO_GB_ID) {
          avalaraItems.push({
            quantity: 1,
            amount: item.price,
            taxCode: "DG020000",
            itemCode: "2876633000000138567",
            description: "Bra - Gift",
          });
        }

        // gift package lines: wellness bundle
        if (item.id === giftItemIds.ZOHO_WB_ID) {
          avalaraItems.push({
            quantity: 1,
            amount: item.price,
            taxCode: "DG020000",
            itemCode: "2876633000000138579",
            description: "Wellness Bundle - Gift",
          });
        }
      }
    }

    const taxInfo = {
      shipToAddress: {
        addressOne: address,
        city: shippingInfo.city,
        state: shippingInfo.state,
        zip: shippingInfo.zip,
        country: shippingInfo.country,
      },
      lines: avalaraItems,
      description: `customer_name: ${props.bagCustomerEmail}, total_sale_price: $${prices.bagTotalPrice}, shipping_cost: $${prices.bagShippingPrice}`,
      gift: gift,
    };
    return taxInfo;
  };

  const updatePrices = async () => {
    // Avalara
    // Ship to address and bagItems
    const taxInfo = compileTaxInfo(false);
    try {
      const response = await axios({
        method: "post",
        url: "/api/avalara/calculateSalesTax",
        data: taxInfo,
      });
      const totalTaxCalculated = response.data.totalTaxCalculated;

      const updatedTaxPrice = addDecimals(totalTaxCalculated);

      const itemTax = addDecimals(
        updatedTaxPrice / bagItems.directPurchases.length
      );

      const bagTaxPrice = itemTax * bagItems.directPurchases.length;
      console.log(bagTaxPrice);

      const updatedTotalPrice = addDecimals(
        Number(prices.bagItemsPrice) +
          Number(prices.bagShippingPrice) +
          Number(bagTaxPrice)
      );

      setPrices({
        ...prices,
        bagTaxPrice: bagTaxPrice,
        bagTotalPrice: updatedTotalPrice,
      });

      // paypal Items
      const items = [];
      for (let i = 0; i < bagItems.directPurchases.length; i++) {
        const braPrices = addDecimals(bagItems.directPurchases[i].braPrice);
        items.push({
          name: `${bagItems.directPurchases[i].braName}-${bagItems.directPurchases[i].braColor}-${bagItems.directPurchases[i].braSize}`,
          tax: { currency_code: "USD", value: `${itemTax}` },
          quantity: "1",
          unit_amount: {
            currency_code: "USD",
            value: `${braPrices}`,
          },
        });
      }

      for (let i = 0; i < bagItems.giftPackages.length; i++) {
        const packagePrice = addDecimals(bagItems.giftPackages[i].packagePrice);
        items.push({
          name: "Reyz Gift Package",
          tax: { currency_code: "USD", value: "0.0" },
          quantity: "1",
          unit_amount: {
            currency_code: "USD",
            value: `${packagePrice}`,
          },
        });
      }

      setPaypalItems(items);
    } catch (error) {
      console.error(error);
    }
  };

  // To Do: Refactor for gifts
  const editEmail = () => {
    setCheckoutProcess({
      editEmail: true,
      emailSubmitted: false,
      editShippingInfo: false,
      shippingInfoSubmitted: false,
    });
  };

  const submitEmail = () => {
    if (useShipping) {
      setCheckoutProcess({
        editEmail: false,
        emailSubmitted: true,
        editShippingInfo: true,
        shippingInfoSubmitted: false,
        paymentAuthorized: false,
      });
    } else {
      setCheckoutProcess({
        editEmail: false,
        emailSubmitted: true,
        editShippingInfo: false,
        shippingInfoSubmitted: true,
        paymentAuthorized: false,
      });
    }
  };

  const editShippingInfo = () => {
    setCheckoutProcess({
      editEmail: false,
      emailSubmitted: true,
      editShippingInfo: true,
      shippingInfoSubmitted: false,
    });
  };

  const submitShippingInfo = async () => {
    //validate
    const taxInfo = compileTaxInfo(false);
    try {
      const response = await axios({
        method: "post",
        url: "/api/avalara/validateShippingAddress",
        data: taxInfo.shipToAddress,
      });
      if (response.data.messages != null) {
        setShow(true);
      } else {
        setShow(false);
        const validatedAddress = response.data.validatedAddresses[0];
        const firstName = shippingInfo.firstName;
        const lastName = shippingInfo.lastName;

        setShippingInfo({
          firstName: firstName,
          lastName: lastName,
          addressOne: validatedAddress.line1,
          addressTwo: "",
          country: "US",
          city: validatedAddress.city,
          state: validatedAddress.region,
          zip: validatedAddress.postalCode,
        });

        setCheckoutProcess({
          editEmail: false,
          emailSubmitted: true,
          editShippingInfo: false,
          shippingInfoSubmitted: true,
        });

        props.saveBagShippingInfo({
          firstName: firstName,
          lastName: lastName,
          addressOne: validatedAddress.line1,
          addressTwo: "",
          country: "US",
          city: validatedAddress.city,
          state: validatedAddress.region,
          zip: validatedAddress.postalCode,
        });

        updatePrices();

        if (prices.bagItemsPrice == 0.0) {
          processFreeOrder();
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const processFreeOrder = async () => {
    setShowOrderIsProcessing(true);
    // start of async calls
    // Create salesOrder
    const orderInfo = compileOrderInfo();
    // 1. creating sales order.
    try {
      await axios({
        method: "post",
        url: "/api/checkout/createSalesOrder",
        data: orderInfo,
      });
    } catch (error) {
      setShowOrderIsProcessing(false);
      setErrorAlert({
        show: true,
        message: "Sorry, there is a problem on our end!",
      });
      return;
    }

    let gift = false;
    if (
      bagItems.giftPackages.length > 0 &&
      bagItems.directPurchases.length < 1
    ) {
      gift = true;
    }
    // 2. Create Avalara Transaction.
    const taxInfo = compileTaxInfo(gift);
    try {
      await axios({
        method: "post",
        url: "/api/avalara/createTransaction",
        data: taxInfo,
      });
    } catch (error) {
      setShowOrderIsProcessing(false);
      setErrorAlert({
        show: true,
        message: "Sorry, there is a problem on our end!",
      });
      return;
    }

    const redemptionLinks = [];
    // If there is a gift
    // Handle gift package stuff
    if (bagItems.giftPackages.length > 0) {
      // Create giftPackage DB entries
      // For each gift package
      for (let i = 0; i < bagItems.giftPackages.length; i++) {
        const giftPackage = bagItems.giftPackages[i];

        // create an array of each packages item ids
        const itemIds = [];
        for (let j = 0; j < giftPackage.items.length; j++) {
          itemIds.push(giftPackage.items[j].id);
        }

        const giftPackageInfo = {
          packageItems: itemIds,
          senderName: giftPackage.senderName,
          senderMessage: giftPackage.message,
          eCard: giftPackage.eCardSrc,
        };

        // 3. Create the giftPackage DB entry
        let giftPackageResponse = {};
        try {
          giftPackageResponse = await axios({
            method: "post",
            url: "/api/gifting/createGiftPackage",
            data: giftPackageInfo,
          });
        } catch (error) {
          setShowOrderIsProcessing(false);
          setErrorAlert({
            show: true,
            message: "Sorry, there is a problem on our end!",
          });
          return;
        }

        // Send giftPackage notification
        const giftPackageId = giftPackageResponse.data;

        let deliveryMethodText = "";
        if (giftPackage.deliveryMethod === "0") {
          deliveryMethodText = "Your eCard has been sent";
        } else {
          deliveryMethodText = "You elected to send your eCard";
        }

        // 4. Get gift redeem URL
        const getRedeemUrl = await axios.get("/api/gifting/getGiftRedeemUrl");
        const redeemUrlPrefix = getRedeemUrl.data.GIFT_REDEEM_URL;
        const url = `${redeemUrlPrefix}/redeem-e-gift/${giftPackageId}`;
        redemptionLinks.push(url);

        const emailToSenderParams = {
          delivery_method: deliveryMethodText,
          recipient_name: giftPackage.recipientName,
          gift_redemption_link: url,
          gifter_email: props.bagCustomerEmail,
        };

        try {
          // 5. send email to sender
          await emailjs.send(
            "service_342gspg",
            "template_0xhc58f",
            emailToSenderParams,
            "user_d8nqj2B3imsBkvM3lzTeV"
          );

          // 6. Send email to recipeint
          if (giftPackage.deliveryMethod === "0") {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");

            const image = new Image(); // Using optional size for image
            // Load an image of intrinsic src size in CSS pixels
            image.src = giftPackage.eCardSrc;
            // Use the intrinsic size of image in CSS pixels for the canvas element
            canvas.width = image.naturalWidth;
            canvas.height = image.naturalHeight;
            ctx.drawImage(image, 0, 0);
            const jpegUrl = canvas.toDataURL("image/jpeg");

            const emailToRecipientParams = {
              sender_name: giftPackage.senderName,
              redemption_code: giftPackageId,
              recipient_email: giftPackage.recipientEmail,
              redemption_link: url,
              // ecard: jpegUrl,
            };

            await emailjs.send(
              "service_342gspg",
              "template_enqouhs",
              emailToRecipientParams,
              "user_d8nqj2B3imsBkvM3lzTeV"
            );
          }
        } catch (e) {
          console.log(e);
        }
      }
      props.addRedemptionLinks(redemptionLinks);
    }
    props.setBagToEmptyState();
    setShowOrderIsProcessing(false);
    props.history.push("/order-confirmation");
  };
  const compileOrderInfo = () => {
    const orderItems = [];
    for (let i = 0; i < bagItems.directPurchases.length; i++) {
      orderItems.push({
        item_id: bagItems.directPurchases[i].selectedItemId,
        name: `${bagItems.directPurchases[i].braName}-${bagItems.directPurchases[i].braColor}-${bagItems.directPurchases[i].braSize}`,
      });
    }
    for (let i = 0; i < bagItems.giftPackages.length; i++) {
      for (let j = 0; j < bagItems.giftPackages[i].items.length; j++) {
        orderItems.push({
          item_id: bagItems.giftPackages[i].items[j].id,
          name: bagItems.giftPackages[i].items[j].value,
        });
      }
    }

    const whenAdded = new Date();
    if (ENV === "production") {
      return {
        contactData: {
          contact_name: `${shippingInfo.firstName} ${shippingInfo.lastName}-${whenAdded}`,
          contact_type: "customer",
          shipping_address: {
            attention: `${shippingInfo.firstName} ${shippingInfo.lastName}`,
            address: shippingInfo.addressOne,
            street2: shippingInfo.addressTwo,
            city: shippingInfo.city,
            state: shippingInfo.state,
            zip: shippingInfo.zip,
            country: shippingInfo.country,
          },
          contact_persons: [
            {
              first_name: shippingInfo.firstName,
              last_name: `${shippingInfo.lastName}-${whenAdded}`,
              email: props.bagCustomerEmail,
              is_primary_contact: true,
            },
          ],
          is_taxable: false,
          tax_authority_id: "2876633000000111006",
          tax_exemption_id: "2876633000000111014",
          tax_authority_name: "Michigan Department of Treasury",
          tax_exemption_code: "NEXUS",
        },
        orderItems: orderItems,
        notes: `Tax: $${prices.bagTaxPrice}, Shipping: $${prices.bagShippingPrice}, Total Paid: $${prices.bagTotalPrice}, Promo Applied: ${promoApplied}`,
      };
    } else {
      return {
        contactData: {
          contact_name: `${shippingInfo.firstName} ${shippingInfo.lastName}-${whenAdded}`,
          contact_type: "customer",
          shipping_address: {
            attention: `${shippingInfo.firstName} ${shippingInfo.lastName}`,
            address: shippingInfo.addressOne,
            street2: shippingInfo.addressTwo,
            city: shippingInfo.city,
            state: shippingInfo.state,
            zip: shippingInfo.zip,
            country: shippingInfo.country,
          },
          contact_persons: [
            {
              first_name: shippingInfo.firstName,
              last_name: `${shippingInfo.lastName}-${whenAdded}`,
              email: props.bagCustomerEmail,
              is_primary_contact: true,
            },
          ],
        },
        orderItems: orderItems,
        notes: `Tax: $${prices.bagTaxPrice}, Shipping: $${prices.bagShippingPrice}, Total Paid: $${prices.bagTotalPrice}, Promo Applied: ${promoApplied}`,
      };
    }
  };

  // Create and append pin checkout event script
  const pinterestCheckoutEvent = () => {
    try {
      const script = document.createElement("script");
      const inlineScript = document.createTextNode(`
    pintrk('track', 'checkout', {
    value: ${prices.bagItemsPrice},
    order_quantity: 1,
    currency: 'USD'
    });`);
      script.appendChild(inlineScript);
      document.head.appendChild(script);
    } catch (e) {
      console.log(e);
    }
  };

  const authorizationApprovedHandler = async (authorizationID) => {
    setShowOrderIsProcessing(true);
    // start of async calls
    // Create salesOrder
    const orderInfo = compileOrderInfo();
    // 1. creating sales order.
    try {
      await axios({
        method: "post",
        url: "/api/checkout/createSalesOrder",
        data: orderInfo,
      });
    } catch (error) {
      setShowOrderIsProcessing(false);
      setErrorAlert({
        show: true,
        message: "Sorry, there is a problem on our end!",
      });
      return;
    }

    let gift = false;
    if (
      bagItems.giftPackages.length > 0 &&
      bagItems.directPurchases.length < 1
    ) {
      gift = true;
    }
    // 2. Create Avalara Transaction.
    const taxInfo = compileTaxInfo(gift);
    try {
      await axios({
        method: "post",
        url: "/api/avalara/createTransaction",
        data: taxInfo,
      });
    } catch (error) {
      setShowOrderIsProcessing(false);
      setErrorAlert({
        show: true,
        message: "Sorry, there is a problem on our end!",
      });
      return;
    }

    const redemptionLinks = [];
    // If there is a gift
    // Handle gift package stuff
    if (bagItems.giftPackages.length > 0) {
      // Create giftPackage DB entries
      // For each gift package
      for (let i = 0; i < bagItems.giftPackages.length; i++) {
        const giftPackage = bagItems.giftPackages[i];

        // create an array of each packages item ids
        const itemIds = [];
        for (let j = 0; j < giftPackage.items.length; j++) {
          itemIds.push(giftPackage.items[j].id);
        }

        const giftPackageInfo = {
          packageItems: itemIds,
          senderName: giftPackage.senderName,
          senderMessage: giftPackage.message,
          eCard: giftPackage.eCardSrc,
        };

        // 3. Create the giftPackage DB entry
        let giftPackageResponse = {};
        try {
          giftPackageResponse = await axios({
            method: "post",
            url: "/api/gifting/createGiftPackage",
            data: giftPackageInfo,
          });
        } catch (error) {
          setShowOrderIsProcessing(false);
          setErrorAlert({
            show: true,
            message: "Sorry, there is a problem on our end!",
          });
          return;
        }

        // Send giftPackage notification
        const giftPackageId = giftPackageResponse.data;

        let deliveryMethodText = "";
        if (giftPackage.deliveryMethod === "0") {
          deliveryMethodText = "Your eCard has been sent";
        } else {
          deliveryMethodText = "You elected to send your eCard";
        }

        // 4. Get gift redeem URL
        const getRedeemUrl = await axios.get("/api/gifting/getGiftRedeemUrl");
        const redeemUrlPrefix = getRedeemUrl.data.GIFT_REDEEM_URL;
        const url = `${redeemUrlPrefix}/redeem-e-gift/${giftPackageId}`;
        redemptionLinks.push(url);

        const emailToSenderParams = {
          delivery_method: deliveryMethodText,
          recipient_name: giftPackage.recipientName,
          gift_redemption_link: url,
          gifter_email: props.bagCustomerEmail,
        };

        try {
          // 5. send email to sender
          await emailjs.send(
            "service_342gspg",
            "template_0xhc58f",
            emailToSenderParams,
            "user_d8nqj2B3imsBkvM3lzTeV"
          );

          // 6. Send email to recipeint
          if (giftPackage.deliveryMethod === "0") {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");

            const image = new Image(); // Using optional size for image
            // Load an image of intrinsic src size in CSS pixels
            image.src = giftPackage.eCardSrc;
            // Use the intrinsic size of image in CSS pixels for the canvas element
            canvas.width = image.naturalWidth;
            canvas.height = image.naturalHeight;
            ctx.drawImage(image, 0, 0);
            const jpegUrl = canvas.toDataURL("image/jpeg");

            const emailToRecipientParams = {
              sender_name: giftPackage.senderName,
              redemption_code: giftPackageId,
              recipient_email: giftPackage.recipientEmail,
              redemption_link: url,
              // ecard: jpegUrl,
            };

            await emailjs.send(
              "service_342gspg",
              "template_enqouhs",
              emailToRecipientParams,
              "user_d8nqj2B3imsBkvM3lzTeV"
            );
          }
        } catch (e) {
          console.log(e);
        }
      }
      props.addRedemptionLinks(redemptionLinks);
    }

    // capture paypal payment
    const dataJson = JSON.stringify({
      authorizationID: authorizationID,
    });

    // 7. Call your server to validate and capture the transaction (successPaymentHandler)
    try {
      const capture = await fetch("/api/checkout/capturePayment", {
        method: "post",
        headers: {
          "content-type": "application/json",
        },
        body: dataJson,
      });
      if (ENV === "development") {
        console.log(capture);
      }
      if (capture.ok) {
        // continue to order confirmation screen
        pinterestCheckoutEvent();
        props.setBagToEmptyState();
        setShowOrderIsProcessing(false);
        props.history.push("/order-confirmation");
      }
    } catch (error) {
      setShowOrderIsProcessing(false);
      setErrorAlert({
        show: true,
        message: error.message,
      });
      return;
    }
  };

  const cardInfoSubmitted = async (billingInfo) => {
    console.log(billingInfo);
    setShowOrderIsProcessing(true);
    // start of async calls
    // Create salesOrder
    const orderInfo = compileOrderInfo();
    // 1. creating sales order.
    try {
      await axios({
        method: "post",
        url: "/api/checkout/createSalesOrder",
        data: orderInfo,
      });
    } catch (error) {
      setShowOrderIsProcessing(false);
      setErrorAlert({
        show: true,
        message: "Sorry, there is a problem on our end!",
      });
      return;
    }

    let gift = false;
    if (
      bagItems.giftPackages.length > 0 &&
      bagItems.directPurchases.length < 1
    ) {
      gift = true;
    }
    // 2. Create Avalara Transaction.
    const taxInfo = compileTaxInfo(gift);
    try {
      await axios({
        method: "post",
        url: "/api/avalara/createTransaction",
        data: taxInfo,
      });
    } catch (error) {
      setShowOrderIsProcessing(false);
      setErrorAlert({
        show: true,
        message: "Sorry, there is a problem on our end!",
      });
      return;
    }

    const redemptionLinks = [];
    // If there is a gift
    // Handle gift package stuff
    if (bagItems.giftPackages.length > 0) {
      // Create giftPackage DB entries
      // For each gift package
      for (let i = 0; i < bagItems.giftPackages.length; i++) {
        const giftPackage = bagItems.giftPackages[i];

        // create an array of each packages item ids
        const itemIds = [];
        for (let j = 0; j < giftPackage.items.length; j++) {
          itemIds.push(giftPackage.items[j].id);
        }

        const giftPackageInfo = {
          packageItems: itemIds,
          senderName: giftPackage.senderName,
          senderMessage: giftPackage.message,
          eCard: giftPackage.eCardSrc,
        };

        // 3. Create the giftPackage DB entry
        let giftPackageResponse = {};
        try {
          giftPackageResponse = await axios({
            method: "post",
            url: "/api/gifting/createGiftPackage",
            data: giftPackageInfo,
          });
        } catch (error) {
          setShowOrderIsProcessing(false);
          setErrorAlert({
            show: true,
            message: "Sorry, there is a problem on our end!",
          });
          return;
        }

        // Send giftPackage notification
        const giftPackageId = giftPackageResponse.data;

        let deliveryMethodText = "";
        if (giftPackage.deliveryMethod === "0") {
          deliveryMethodText = "Your eCard has been sent";
        } else {
          deliveryMethodText = "You elected to send your eCard";
        }

        // 4. Get gift redeem URL
        const getRedeemUrl = await axios.get("/api/gifting/getGiftRedeemUrl");
        const redeemUrlPrefix = getRedeemUrl.data.GIFT_REDEEM_URL;
        const url = `${redeemUrlPrefix}/redeem-e-gift/${giftPackageId}`;
        redemptionLinks.push(url);

        const emailToSenderParams = {
          delivery_method: deliveryMethodText,
          recipient_name: giftPackage.recipientName,
          gift_redemption_link: url,
          gifter_email: props.bagCustomerEmail,
        };

        try {
          // 5. send email to sender
          await emailjs.send(
            "service_342gspg",
            "template_0xhc58f",
            emailToSenderParams,
            "user_d8nqj2B3imsBkvM3lzTeV"
          );

          // 6. Send email to recipeint
          if (giftPackage.deliveryMethod === "0") {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");

            const image = new Image(); // Using optional size for image
            // Load an image of intrinsic src size in CSS pixels
            image.src = giftPackage.eCardSrc;
            // Use the intrinsic size of image in CSS pixels for the canvas element
            canvas.width = image.naturalWidth;
            canvas.height = image.naturalHeight;
            ctx.drawImage(image, 0, 0);
            const jpegUrl = canvas.toDataURL("image/jpeg");

            const emailToRecipientParams = {
              sender_name: giftPackage.senderName,
              redemption_code: giftPackageId,
              recipient_email: giftPackage.recipientEmail,
              redemption_link: url,
              // ecard: jpegUrl,
            };

            await emailjs.send(
              "service_342gspg",
              "template_enqouhs",
              emailToRecipientParams,
              "user_d8nqj2B3imsBkvM3lzTeV"
            );
          }
        } catch (e) {
          console.log(e);
        }
      }
      props.addRedemptionLinks(redemptionLinks);
    }

    // 7. Call your server to validate and capture the transaction
    // Try to create
    try {
      const response = await axios({
        method: "post",
        url: "/api/authnet/chargeCreditCard",
        data: {
          ...billingInfo,
          amount: prices.bagTotalPrice,
        },
      });
      if (ENV === "development") {
        console.log(response);
      }
      if (response.data.success) {
        // continue to order confirmation screen
        pinterestCheckoutEvent();
        props.setBagToEmptyState();
        setShowOrderIsProcessing(false);
        props.history.push("/order-confirmation");
      } else {
        throw new Error(response.data.errorMessage);
      }
    } catch (error) {
      setShowOrderIsProcessing(false);
      setErrorAlert({
        show: true,
        message: error.message,
      });
      return;
    }
  };

  useEffect(() => {
    if (bagItems.directPurchases.length > 0) {
      setUseShipping(true);
    }

    const addPayPalScript = async () => {
      const { data: clientId } = await axios.get("/api/checkout/config/paypal");
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = `https://www.paypal.com/sdk/js?client-id=${clientId}&intent=authorize&disable-funding=paylater,card`;
      script.async = true;
      script.onload = () => {
        setPayPalSdkReady(true);
      };
      document.body.appendChild(script);
    };

    if (!window.paypal) {
      try {
        addPayPalScript();
      } catch (error) {
        setErrorAlert({
          show: true,
          message: error.message,
        });
        return;
      }
    } else {
      setPayPalSdkReady(true);
    }
  }, [payPalSdkReady, bagItems]);

  return (
    <div>
      <Helmet>
        <script id="pintrkCheckout">
          {`!function(e){if(!window.pintrk){window.pintrk = function () {
window.pintrk.queue.push(Array.prototype.slice.call(arguments))};var
  n=window.pintrk;n.queue=[],n.version="3.0";var
  t=document.createElement("script");t.async=!0,t.src=e;var
  r=document.getElementsByTagName("script")[0];
  r.parentNode.insertBefore(t,r)}}("https://s.pinimg.com/ct/core.js");
pintrk('load', '2612501360262', {em: '${props.bagCustomerEmail}'});
pintrk('page');`}
        </script>
        <noscript>
          {`<img height="1" width="1" style="display:none;" alt=""
  src="https://ct.pinterest.com/v3/?event=init&tid=2612501360262&noscript=1" />`}
        </noscript>
      </Helmet>
      <Modal
        show={showOrderIsProcessing}
        style={{ contentAlign: "center" }}
        animation={false}
      >
        <Modal.Body
          style={{
            textAlign: "center",
            verticalAlign: "center",
          }}
        >
          <p>
            We are processing your order! <br /> Please do not close this tab
            until you reach the confirmation page.
          </p>
          <Spinner
            animation="border"
            role="status"
            style={{
              width: "50px",
              height: "50px",
              margin: "auto",
            }}
          />
        </Modal.Body>
      </Modal>
      <Row>
        <Col lg={{ span: 6, order: "first" }} xs={{ span: 12, order: "last" }}>
          <div style={{ paddingBottom: "16px" }}>
            <EmailCard
              checkoutProcess={checkoutProcess}
              editEmail={editEmail}
              submitEmail={submitEmail}
            />
          </div>

          {useShipping && (
            <div style={{ paddingBottom: "16px" }}>
              <Toast
                className="w-100"
                onClose={() => setShow(false)}
                show={show}
                delay={5000}
                autohide
                bg="warning"
              >
                <Toast.Header>
                  <strong className="me-auto">Oops!</strong>
                </Toast.Header>
                <Toast.Body>
                  Please enter a correct shipping address.
                </Toast.Body>
              </Toast>

              <ShippingCard
                checkoutProcess={checkoutProcess}
                shippingInfo={shippingInfo}
                setShippingInfo={setShippingInfo}
                editShippingInfo={editShippingInfo}
                submitShippingInfo={submitShippingInfo}
              />
            </div>
          )}

          <div style={{ paddingBottom: "16px" }}>
            <Alert
              show={errorAlert.show}
              variant="danger"
              onClose={() => setErrorAlert({ ...errorAlert, show: false })}
              dismissible
            >
              <Alert.Heading>Sorry, something went wrong.</Alert.Heading>
              <p>{errorAlert.message}</p>
            </Alert>
            <PaymentCard
              checkoutProcess={checkoutProcess}
              paymentAmount={prices.bagTotalPrice.toString()}
              authorizationApprovedHandler={authorizationApprovedHandler}
              cardInfoSubmitted={cardInfoSubmitted}
              setErrorAlert={setErrorAlert}
              prices={prices}
              shippingInfo={bagShippingInfo}
              paypalItems={paypalItems}
              useShipping={useShipping}
            />
          </div>
        </Col>
        <Col lg={{ span: 6, order: "last" }} xs={{ span: 12, order: "first" }}>
          <OrderSummary bagItems={bagItems} prices={prices} />
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    bagItems: state.bag.bagItems,
    promoApplied: state.bag.promoApplied,
    bagCustomerEmail: state.bag.bagCustomerEmail,
    bagShippingInfo: state.bag.bagShippingInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setBagToEmptyState: () => dispatch(setBagToEmptyState()),
    saveBagShippingInfo: (shippingInfo) =>
      dispatch(saveBagShippingInfo(shippingInfo)),
    addRedemptionLinks: (redemptionLinks) =>
      dispatch(addRedemptionLinks(redemptionLinks)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
