import React, { useRef, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import emailjs from "emailjs-com";

const ProfessionalContactForm = () => {
  const form = useRef();
  const [emailSubmitted, setEmailSubmitted] = useState(false);
  const sendEmail = async (e) => {
    e.preventDefault();

    try {
      const body = JSON.stringify({
        customerEmail: document.getElementById(
          "ProfessionalContactForm.EmailInput"
        ).value,
        list: "subscriber",
      });
      fetch("/api/klaviyo/createContact", {
        method: "post",
        headers: {
          "content-type": "application/json",
        },
        body: body,
      })
        .then((res) => console.log(res))
        .catch((err) => console.error("error:" + err));

      await emailjs.sendForm(
        "service_342gspg",
        "template_4yqavdb",
        form.current,
        "user_d8nqj2B3imsBkvM3lzTeV"
      );

      setEmailSubmitted(true);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div>
      {!emailSubmitted ? (
        <Container style={{ marginBottom: "24px" }}>
          <Row>
            <Col
              xl={{ span: 8, offset: 2 }}
              md={{ span: 8, offset: 2 }}
              sm={{ span: 4 }}
              style={{}}
            >
              <Form ref={form} onSubmit={sendEmail}>
                <Form.Group
                  className="mb-3"
                  controlId="ProfessionalContactForm.NameInput"
                >
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    required
                    type="name"
                    placeholder="Name"
                    name="contact_name"
                  />
                </Form.Group>

                <Form.Group
                  className="mb-3"
                  controlId="ProfessionalContactForm.EmailInput"
                >
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    required
                    type="email"
                    placeholder="Email"
                    name="contact_email"
                  />
                </Form.Group>

                <Form.Group
                  className="mb-3"
                  controlId="ProfessionalContactForm.BusinessNameInput"
                >
                  <Form.Label>Business Name</Form.Label>
                  <Form.Control
                    required
                    type="name"
                    placeholder="Business Name"
                    name="contact_business_name"
                  />
                </Form.Group>

                <Form.Select
                  style={{ marginBottom: "16px" }}
                  name="contact_reason"
                  required
                >
                  <option>Reason for contacting: </option>
                  <option value="I would like to do a feature on Reyz or its founder!">
                    I would like to do a feature on Reyz or its founder!
                  </option>
                  <option value="I am interested in partnering or collaborating!">
                    I am interested in partnering or collaborating!
                  </option>
                  <option value="Other">Other</option>
                </Form.Select>

                <Form.Group
                  className="mb-3"
                  controlId="ProfessionalContactForm.contactTextarea"
                >
                  <Form.Label>
                    If you need other information or have any questions, please
                    leave us a message here:
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    placeholder="Let us know how we can help!"
                    rows={3}
                    name="contact_text"
                  />
                </Form.Group>

                <div className="d-grid col-12">
                  <Button className="w-100" type="submit">
                    Submit
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>
        </Container>
      ) : (
        <p>Thank you!</p>
      )}
    </div>
  );
};

export default ProfessionalContactForm;
