import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import { Modal, Spinner } from "react-bootstrap";
import Loader from "../../../components/Loader";
import RedeemEGiftShipping from "./RedeemEGiftShipping";
import RedeemEGiftSelect from "./RedeemEGiftSelect";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepButton from "@mui/material/StepButton";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/system";
import axios from "axios";

const RedeemEGift = ({ history, match }) => {
  const giftPackageId = match.params.id;
  const giftInfoLoaded = useRef(false);
  const giftItemIdsLoaded = useRef(false);
  const [giftPackage, setGiftPackage] = useState({});
  const [giftItemIds, setGiftItemIds] = useState({
    ZOHO_GB_ID: "",
    ZOHO_WB_ID: "",
  });
  const [activeStep, setActiveStep] = useState(0);
  const [selectedBra, setSelectedBra] = useState({});
  const [braSelected, setBraSelected] = useState(false);
  const [validAddress, setValidAddress] = useState(false);

  const [show, setShow] = useState(false);
  const [errorAlert, setErrorAlert] = useState({ show: false, message: "" });
  const [showOrderIsProcessing, setShowOrderIsProcessing] = useState(false);
  const [shippingInfo, setShippingInfo] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    addressOne: "",
    addressTwo: "",
    country: "US",
    city: "",
    state: "",
    zip: "",
  });

  useEffect(() => {
    const redeemGift = async () => {
      setShowOrderIsProcessing(true);

      const orderInfo = await compileOrderInfo();
      let address = null;
      if (shippingInfo.addressTwo === "" || shippingInfo.addressTwo == null) {
        address = shippingInfo.addressOne;
      } else {
        address = `${shippingInfo.addressOne} ${shippingInfo.addressTwo}`;
      }
      const taxInfo = {
        shipToAddress: {
          addressOne: address,
          city: shippingInfo.city,
          state: shippingInfo.state,
          zip: shippingInfo.zip,
          country: shippingInfo.country,
        },
        lines: orderInfo.avalaraItems,
        gift: false,
      };

      try {
        const body = JSON.stringify({
          customerEmail: shippingInfo.email,
          list: "purchaser",
        });
        fetch("/api/klaviyo/createContact", {
          method: "post",
          headers: {
            "content-type": "application/json",
          },
          body: body,
        })
          .then((res) => console.log(res))
          .catch((err) => console.error("error:" + err));
      } catch (error) {
        console.log(error.message);
      }

      // create sales order
      try {
        await axios({
          method: "post",
          url: "/api/checkout/createSalesOrder",
          data: orderInfo,
        });
      } catch (error) {
        setShowOrderIsProcessing(false);
        setErrorAlert({
          show: true,
          message: "Sorry, there is a problem on our end!",
        });
        return;
      }

      // Update MongoDB: Gift Package redeemed
      try {
        const { data } = await axios.put(
          `/api/gifting/redeemGiftPackage/${giftPackageId}`
        );
        if (data.redeemed) {
          try {
            await axios({
              method: "post",
              url: "/api/avalara/createTransaction",
              data: taxInfo,
            });
          } catch (error) {
            console.error(error);
          }

          // Push to confirmation page
          history.push("/redeem-e-gift/message/1");
        } else {
          throw new Error("Sorry, there is a problem on our end");
        }
      } catch (e) {
        setErrorAlert({
          show: true,
          message: "Sorry, there is a problem on our end!",
        });
      }
    };

    if (validAddress) {
      redeemGift();
      setValidAddress(false);
    }
    // eslint-disable-next-line
  }, [validAddress]);

  useEffect(() => {
    if (!giftInfoLoaded.current) {
      const getGiftPackage = async () => {
        try {
          const { data } = await axios.get(
            `/api/gifting/getGiftPackage/${giftPackageId}`
          );
          giftInfoLoaded.current = true;

          if (data.redeemed) {
            history.push("/redeem-e-gift/message/2");
          } else {
            setGiftPackage(data);
          }
        } catch (e) {
          history.push("/redeem-e-gift/message/3");
        }
      };
      getGiftPackage();
    }

    if (!giftItemIdsLoaded.current) {
      const getGiftItemIds = async () => {
        const getGBid = await axios.get("/api/gifting/getGBid");
        const getWBid = await axios.get("/api/gifting/getWBid");
        const ZOHO_GB_ID = getGBid.data.ZOHO_GB_ID;
        const ZOHO_WB_ID = getWBid.data.ZOHO_WB_ID;

        setGiftItemIds({
          ZOHO_GB_ID: ZOHO_GB_ID,
          ZOHO_WB_ID: ZOHO_WB_ID,
        });

        giftItemIdsLoaded.current = true;
      };

      getGiftItemIds();
    }
  }, [giftPackageId, giftItemIds, history]);

  // Stepper Component
  const theme = createTheme({
    palette: {
      primary: {
        main: "#221551",
      },
      secondary: {
        main: "#833177",
      },
    },
    typography: {
      fontFamily: "Josefin Sans, sans-serif",
    },
  });

  const selectBraOnClickHandler = (chosenBra) => {
    setSelectedBra({
      directPurchases: [
        {
          braName: chosenBra.productName,
          braPrice: 0.0,
          braImage: chosenBra.productImage,
          selectedItemId: chosenBra.selectedItemId,
          quantity: chosenBra.quantity,
          braColor: chosenBra.productColor,
          braSize: chosenBra.productSize,
          whenAdded: chosenBra.whenAdded,
          purchaseType: chosenBra.purchaseType,
        },
      ],
      giftPackages: [],
    });
    setBraSelected(true);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const compileAvalaraInfo = () => {
    let address = null;
    if (shippingInfo.addressTwo === "" || shippingInfo.addressTwo == null) {
      address = shippingInfo.addressOne;
    } else {
      address = `${shippingInfo.addressOne} ${shippingInfo.addressTwo}`;
    }

    const avalaraInfo = {
      shipToAddress: {
        addressOne: address,
        city: shippingInfo.city,
        state: shippingInfo.state,
        zip: shippingInfo.zip,
        country: shippingInfo.country,
      },
    };
    return avalaraInfo;
  };

  // shipping handler
  const submitShippingInfo = async (e) => {
    e.preventDefault();
    //validate
    const avalaraInfo = compileAvalaraInfo();
    try {
      const response = await axios({
        method: "post",
        url: "/api/avalara/validateShippingAddress",
        data: avalaraInfo.shipToAddress,
      });

      if (response.data.messages != null) {
        setShow(true);
        return false;
      } else {
        setShow(false);
        const validatedAddress = response.data.validatedAddresses[0];
        const firstName = shippingInfo.firstName;
        const lastName = shippingInfo.lastName;

        setShippingInfo({
          ...shippingInfo,
          firstName: firstName,
          lastName: lastName,
          addressOne: validatedAddress.line1,
          addressTwo: "",
          country: "US",
          city: validatedAddress.city,
          state: validatedAddress.region,
          zip: validatedAddress.postalCode,
        });
        setValidAddress(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const ENV = "production";
  const compileOrderInfo = async () => {
    const ZOHO_GB_ID = giftItemIds.ZOHO_GB_ID;
    const ZOHO_WB_ID = giftItemIds.ZOHO_WB_ID;
    const orderItems = [];
    const avalaraItems = [];

    if (giftPackage.packageItems.includes(ZOHO_GB_ID)) {
      orderItems.push({
        item_id: selectedBra.directPurchases[0].selectedItemId,
        name: selectedBra.directPurchases[0].braName,
      });

      avalaraItems.push({
        quantity: 1,
        amount: 0.0,
        taxCode: "DG020000",
        itemCode: "2876633000000138567R",
        description: "Bra - Redemption",
      });
    }

    if (giftPackage.packageItems.includes(ZOHO_WB_ID)) {
      orderItems.push({
        item_id: ZOHO_WB_ID,
        name: "Wellness Bundle",
      });

      avalaraItems.push({
        quantity: 1,
        amount: 0.0,
        taxCode: "DG020000",
        itemCode: "2876633000000138579R",
        description: "Wellness Bundle - Redemption",
      });
    }

    const whenAdded = new Date();
    if (ENV === "production") {
      return {
        contactData: {
          contact_name: `${shippingInfo.firstName} ${shippingInfo.lastName}-${whenAdded}`,
          contact_type: "customer",
          shipping_address: {
            attention: `${shippingInfo.firstName} ${shippingInfo.lastName}`,
            address: shippingInfo.addressOne,
            street2: shippingInfo.addressTwo,
            city: shippingInfo.city,
            state: shippingInfo.state,
            zip: shippingInfo.zip,
            country: shippingInfo.country,
          },
          contact_persons: [
            {
              first_name: shippingInfo.firstName,
              last_name: `${shippingInfo.lastName}-${whenAdded}`,
              email: shippingInfo.email,
              phone: shippingInfo.phone,
              is_primary_contact: true,
            },
          ],
          is_taxable: false,
          tax_authority_id: "2876633000000111006",
          tax_exemption_id: "2876633000000111014",
          tax_authority_name: "Michigan Department of Treasury",
          tax_exemption_code: "NEXUS",
        },
        orderItems: orderItems,
        avalaraItems: avalaraItems,
        notes: "Redeemed Gift",
      };
    } else {
      return {
        contactData: {
          contact_name: `${shippingInfo.firstName} ${shippingInfo.lastName}-${whenAdded}`,
          contact_type: "customer",
          shipping_address: {
            attention: `${shippingInfo.firstName} ${shippingInfo.lastName}`,
            address: shippingInfo.addressOne,
            street2: shippingInfo.addressTwo,
            city: shippingInfo.city,
            state: shippingInfo.state,
            zip: shippingInfo.zip,
            country: shippingInfo.country,
          },
          contact_persons: [
            {
              first_name: shippingInfo.firstName,
              last_name: `${shippingInfo.lastName}-${whenAdded}`,
              email: shippingInfo.email,
              phone: shippingInfo.phone,
              is_primary_contact: true,
            },
          ],
        },
        orderItems: orderItems,
        avalaraItems: avalaraItems,
        notes: "Redeemed Gift",
      };
    }
  };

  const handleBack = () => {
    if (braSelected) {
      setBraSelected(false);
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  return (
    <>
      <Helmet>
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Get Your Gift!" />
        <meta
          property="og:description"
          content="Your Reyz gift is waiting for you!"
        />
        {/* <meta
          property="og:image:secure_url"
          content="https://www.givereyz.com/static/media/clouds_inspire.e3e2b9fe.webp"
        />
        <meta property="og:image:alt" content="E-Card Image" />
        <meta property="og:image:type" content="image/webp" /> */}
      </Helmet>
      <Modal
        show={showOrderIsProcessing}
        style={{ contentAlign: "center" }}
        animation={false}
      >
        <Modal.Body
          style={{
            textAlign: "center",
            verticalAlign: "center",
          }}
        >
          <p>
            We are processing your order! <br /> Please do not close this tab
            until you reach the confirmation page.
          </p>
          <Spinner
            animation="border"
            role="status"
            style={{
              width: "50px",
              height: "50px",
              margin: "auto",
            }}
          />
        </Modal.Body>
      </Modal>
      {giftInfoLoaded.current ? (
        <div>
          <div className="justify-content-center mb-4 col-lg-4 offset-lg-4">
            <ThemeProvider theme={theme}>
              <Stepper className="muiStep" activeStep={activeStep}>
                <Step key="Select Your Bra">
                  <StepButton onClick={handleBack}>Select Your Bra</StepButton>
                </Step>
                <Step key="Enter Shipping Info">
                  <StepLabel>Enter Shipping Info</StepLabel>
                </Step>
              </Stepper>
            </ThemeProvider>
          </div>
          {braSelected ? (
            <RedeemEGiftShipping
              bagItems={selectedBra}
              shippingSubmitHandler={submitShippingInfo}
              show={show}
              setShow={setShow}
              errorAlert={errorAlert}
              setErrorAlert={setErrorAlert}
              shippingInfo={shippingInfo}
              setShippingInfo={setShippingInfo}
            />
          ) : (
            <RedeemEGiftSelect
              history={history}
              selectBraOnClickHandler={selectBraOnClickHandler}
              giftPackage={giftPackage}
            />
          )}
        </div>
      ) : (
        <Loader message={"Please wait while we load your gift!"} />
      )}
    </>
  );
};

export default RedeemEGift;
