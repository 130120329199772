import { combineReducers } from "redux";
import braReducer from "./bras/braReducer";
import bagReducer from "./bag/bagReducer";
import giftItemsReducer from "./giftItems/giftItemsReducer";
import redeemLinksReducer from "./redeemLinks/redeemLinksReducer";

const rootReducer = combineReducers({
  bra: braReducer,
  bag: bagReducer,
  giftItems: giftItemsReducer,
  redemptionLinks: redeemLinksReducer,
});

export default rootReducer;
