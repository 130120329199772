import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const Testimonials = (props) => {
  return (
    <section
      style={{
        backgroundColor: "#221551",
        display: "grid",
        gridTemplateColumns: "1fr",
        gridTemplateRows: "1fr",
        gridTemplateAreas: "overlap",
      }}
    >
      <div
        style={{
          marginLeft: "-100vw",
          marginRight: "-100vw",
          height: "100%",
          backgroundColor: "#221551",
          gridArea: "overlap",
        }}
      />
      <div
        id="testimonials"
        style={{
          textAlign: "center",
          paddingTop: "32px",
          paddingBottom: "32px",

          fontSize: "1.5rem",
          color: "#fff",
          gridArea: "overlap",
        }}
      >
        <Container style={{ justifyContent: "center" }}>
          <Row>
            <h2
              style={{ color: "#fff", marginBottom: "32px", fontSize: "2em" }}
            >
              Reyz Praise
            </h2>
          </Row>
          <Row>
            <Col xl={4} md={4} sm={4} style={{ marginBottom: "36px" }}>
              <p style={{ marginBottom: "0px" }}>
                “So comfortable, I forgot I had it on and slept in it.”
              </p>
              <p style={{ marginBottom: "0px" }}>- Courtney</p>
            </Col>
            <Col xl={4} md={4} sm={4} style={{ marginBottom: "36px" }}>
              <p style={{ marginBottom: "0px" }}>
                “Helped me avoid the stress of finding the right bra after
                surgery.”
              </p>
              <p style={{ marginBottom: "0px" }}>- Celeste</p>
            </Col>
            <Col xl={4} md={4} sm={4} style={{ marginBottom: "36px" }}>
              <p style={{ marginBottom: "0px" }}>
                “I’m a soft bra person, and this is the most comfortable I’ve
                tried.”
              </p>
              <p style={{ marginBottom: "0px" }}>- LaTonya</p>
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
};

export default Testimonials;
