import React, { useEffect } from "react";

const RedeemEGiftMessage = ({ match }) => {
  useEffect(() => {
    document.title = "Redemption Message";
  }, []);

  const messageId = match.params.id;

  return (
    <div style={{ textAlign: "center" }}>
      {messageId === "1" && (
        <div>
          <h2>Best Supporting Hero</h2>
          <p>Your order is complete!</p>
        </div>
      )}
      {messageId === "2" && (
        <div>
          <h2>Sorry!</h2>
          <p>This gift has already been redeemed.</p>
        </div>
      )}
      {messageId === "3" && (
        <div>
          <h2>Sorry!</h2>
          <p>The gift code entered is not correct.</p>
        </div>
      )}
    </div>
  );
};

export default RedeemEGiftMessage;
