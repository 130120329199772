import Button from "react-bootstrap/Button";
import React from "react";
import { Row, Col, Image } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const BagGiftItem = (props) => {
  return (
    <div>
      <Row
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {/* Remove Item */}
        <Col lg={1} md={1} xs={2}>
          {props.showRemoveButton && (
            <Button
              type="button"
              variant="light"
              onClick={() =>
                props.removeFromBagHandler(
                  props.item.whenAdded,
                  props.item.purchaseType
                )
              }
              style={{
                backgroundColor: "transparent",
                borderColor: "transparent",
              }}
            >
              <FontAwesomeIcon icon={faTimes} />
            </Button>
          )}
        </Col>
        {/* E-Card */}
        <Col lg={3} md={2} xs={2}>
          <Image src={props.item.eCardSrc} fluid></Image>
        </Col>
        {/* Package Items */}
        <Col lg={5} md={6} xs={5}>
          <ul>
            <li
              key="gift-package-items-header"
              style={{ listStyleType: "none" }}
            >
              <h6>Package Items</h6>
            </li>
            {props.item.items.map((item) => (
              <li key={item.value}>{item.value}</li>
            ))}
          </ul>
        </Col>
        {/* Package Price */}
        <Col lg={3} md={3} xs={3}>
          ${props.item.packagePrice}
        </Col>
      </Row>
      <hr />
    </div>
  );
};

export default BagGiftItem;
