import React from "react";
import { connect } from "react-redux";
import { Card, Button, Form, Row, Col } from "react-bootstrap";

const ShippingCard = (props) => {
  const shippingSubmitHandler = (e) => {
    e.preventDefault();
    props.submitShippingInfo();
  };

  return (
    <Card>
      <Card.Header>
        Shipping
        {props.checkoutProcess.shippingInfoSubmitted && (
          <Button
            variant="link"
            style={{ float: "right" }}
            onClick={() => props.editShippingInfo()}
          >
            Edit
          </Button>
        )}
      </Card.Header>
      {props.checkoutProcess.emailSubmitted && (
        <Card.Body>
          <Form onSubmit={shippingSubmitHandler}>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridFName">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  required
                  type="firstname"
                  placeholder="first"
                  value={props.shippingInfo.firstName || ""}
                  onChange={(e) =>
                    props.setShippingInfo({
                      ...props.shippingInfo,
                      firstName: e.target.value,
                    })
                  }
                  readOnly={!props.checkoutProcess.editShippingInfo}
                />
              </Form.Group>

              <Form.Group as={Col} controlId="formGridLName">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  required
                  type="lastname"
                  placeholder="last"
                  value={props.shippingInfo.lastName || ""}
                  onChange={(e) =>
                    props.setShippingInfo({
                      ...props.shippingInfo,
                      lastName: e.target.value,
                    })
                  }
                  readOnly={!props.checkoutProcess.editShippingInfo}
                />
              </Form.Group>
            </Row>

            <Form.Group className="mb-3" controlId="formGridAddress1">
              <Form.Label>Address</Form.Label>
              <Form.Control
                required
                placeholder="1234 Main St"
                value={props.shippingInfo.addressOne || ""}
                onChange={(e) =>
                  props.setShippingInfo({
                    ...props.shippingInfo,
                    addressOne: e.target.value,
                  })
                }
                readOnly={!props.checkoutProcess.editShippingInfo}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formGridAddress2">
              <Form.Label>Address 2</Form.Label>
              <Form.Control
                placeholder="Apartment, studio, or floor"
                value={props.shippingInfo.addressTwo || ""}
                onChange={(e) =>
                  props.setShippingInfo({
                    ...props.shippingInfo,
                    addressTwo: e.target.value,
                  })
                }
                readOnly={!props.checkoutProcess.editShippingInfo}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formGridCountry">
              <Form.Label>Country</Form.Label>
              <Form.Control
                required
                placeholder="country"
                value={props.shippingInfo.country || "US"}
                onChange={(e) =>
                  props.setShippingInfo({
                    ...props.shippingInfo,
                    country: e.target.value,
                  })
                }
                readOnly={true}
              />
            </Form.Group>

            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridCity">
                <Form.Label>City</Form.Label>
                <Form.Control
                  required
                  placeholder="city"
                  value={props.shippingInfo.city || ""}
                  onChange={(e) =>
                    props.setShippingInfo({
                      ...props.shippingInfo,
                      city: e.target.value,
                    })
                  }
                  readOnly={!props.checkoutProcess.editShippingInfo}
                />
              </Form.Group>

              <Form.Group as={Col} controlId="formGridState">
                <Form.Label>State</Form.Label>
                <Form.Control
                  required
                  placeholder="state"
                  value={props.shippingInfo.state || ""}
                  onChange={(e) =>
                    props.setShippingInfo({
                      ...props.shippingInfo,
                      state: e.target.value,
                    })
                  }
                  readOnly={!props.checkoutProcess.editShippingInfo}
                />
              </Form.Group>
            </Row>

            <Row>
              <Form.Group as={Col} controlId="formGridZip">
                <Form.Label>Zip</Form.Label>
                <Form.Control
                  required
                  placeholder="12345"
                  value={props.shippingInfo.zip || ""}
                  onChange={(e) =>
                    props.setShippingInfo({
                      ...props.shippingInfo,
                      zip: e.target.value,
                    })
                  }
                  readOnly={!props.checkoutProcess.editShippingInfo}
                />
              </Form.Group>
            </Row>

            {props.checkoutProcess.editShippingInfo && (
              <div
                className="d-grid col-12"
                style={{ marginBottom: "8px", marginTop: "16px" }}
              >
                <Button className="w-100" type="submit">
                  Continue
                </Button>
              </div>
            )}
          </Form>
        </Card.Body>
      )}
    </Card>
  );
};

const mapStateToProps = (state) => {
  return {
    bagShippingInfo: state.bag.bagShippingInfo,
  };
};

export default connect(mapStateToProps)(ShippingCard);
