import React from "react";
import { useHistory } from "react-router-dom";
import Button from "react-bootstrap/Button";
import heroImage from "../assets/images/hero-section/KBM04562-op.webp";

const HeroSectionVTwo = () => {
  const history = useHistory();

  return (
    <div
      style={{
        position: "relative",
        background: `url(${heroImage}) center center/cover no-repeat`,
        backgroundPosition: "0px -30px",
        height: "120vh",
        width: "100vw",
        left: "50%",
        right: "50%",
        marginTop: "-16px",
        marginLeft: "-50vw",
        marginRight: "-50vw",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
        justifyContent: "center",
      }}
    >
      <h1 style={{ color: "#fff", fontSize: "2.5em" }}>
        Luxurious Feel for Sensitive Skin
      </h1>
      <Button
        onClick={() => {
          history.push("/product");
        }}
      >
        Shop Now
      </Button>
    </div>
  );
};

export default HeroSectionVTwo;
