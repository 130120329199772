import React, { useState } from "react";
import { Button, Col, Container, Row, Form } from "react-bootstrap";
import { Toast } from "react-bootstrap";
import cloudsInspire from "../../../assets/images/cards/clouds_inspire.webp";
import Image from "react-bootstrap/Image";
import SelectECardModal from "../../../components/gifting/SelectECardModal";

const SendAGift = (props) => {
  const [show, setShow] = useState(false);
  const [showWarning, setShowWarning] = useState({ show: false, warning: "" });

  const [showEmail, setShowEmail] = useState(false);

  const [giftDetails, setGiftDetails] = useState({
    items: props.items,
    eCardSrc: cloudsInspire,
    senderName: "",
    recipientName: "",
    recipientEmail: "",
    message: "",
    deliveryMethod: -1,
  });

  const onSubmit = (e) => {
    e.preventDefault();
    // eslint-disable-next-line
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (giftDetails.deliveryMethod === -1) {
      setShowWarning({
        show: true,
        warning: "Please select a delivery Method.",
      });
    } else if (showEmail && !giftDetails.recipientEmail.match(mailformat)) {
      setShowWarning({
        show: true,
        warning: "Please enter a vaild email address.",
      });
    } else {
      const whenAdded = new Date();
      let packagePrice = giftDetails.items.reduce(
        (acc, item) => acc + item.price,
        0
      );
      let giftPackage = {
        items: giftDetails.items,
        eCardSrc: giftDetails.eCardSrc,
        senderName: giftDetails.senderName,
        recipientName: giftDetails.recipientName,
        recipientEmail: giftDetails.recipientEmail,
        message: giftDetails.message,
        deliveryMethod: giftDetails.deliveryMethod,
        whenAdded: whenAdded,
        packagePrice: packagePrice,
        purchaseType: "GIFT",
      };
      props.addGiftToBag(giftPackage);
      props.history.push("/bag");
    }
  };

  return (
    <>
      <SelectECardModal
        show={show}
        setShow={setShow}
        giftDetails={giftDetails}
        setGiftDetails={setGiftDetails}
      />

      <div style={{ marginBottom: "16px" }}>
        <h3>Gift Details</h3>
        <Container>
          <Form onSubmit={(e) => onSubmit(e)}>
            <Row>
              <Col lg={6} md={6} sm={12} xs={12}>
                <label>Items</label>
                <ul>
                  {giftDetails.items.map((item) => (
                    <li key={item.id}>{item.value}</li>
                  ))}
                </ul>
                <label>E-Card</label>
                <Button variant="link" onClick={() => setShow(true)}>
                  MORE E-CARD OPTIONS
                </Button>
                <div style={{ marginBottom: "8px" }}>
                  <Image
                    className="e-card-image img-fluid"
                    src={giftDetails.eCardSrc}
                  />
                </div>

                <Form.Group className="mb-3" controlId="giftForm.senderInput">
                  <Form.Label>From</Form.Label>
                  <Form.Control
                    required
                    type="name"
                    placeholder="Sender Name"
                    onChange={(e) => {
                      setGiftDetails({
                        ...giftDetails,
                        senderName: e.target.value,
                      });
                    }}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="giftForm.senderInput">
                  <Form.Label>To</Form.Label>
                  <Form.Control
                    required
                    type="name"
                    placeholder="Reciever Name"
                    onChange={(e) => {
                      setGiftDetails({
                        ...giftDetails,
                        recipientName: e.target.value,
                      });
                    }}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="giftForm.giftMessage">
                  <Form.Label>Message</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Additional personal message, if you’d like."
                    onChange={(e) => {
                      setGiftDetails({
                        ...giftDetails,
                        message: e.target.value,
                      });
                    }}
                  />
                </Form.Group>
              </Col>
              <Col lg={6} md={6} sm={12} xs={12}>
                <div style={{ paddingBottom: "8px" }}>
                  <Toast
                    className="w-100"
                    onClose={() => setShowWarning({ show: false, warning: "" })}
                    show={showWarning.show}
                    delay={5000}
                    autohide
                    bg="warning"
                  >
                    <Toast.Header>
                      <strong className="me-auto">Oops!</strong>
                    </Toast.Header>
                    <Toast.Body>{showWarning.warning}</Toast.Body>
                  </Toast>
                  <Form.Group>
                    <Form.Label>E-Gift Delivery Method</Form.Label>
                    <Form.Check
                      type="radio"
                      name="delivery-method"
                      label="We email (to an email you provide)"
                      value={0}
                      onChange={(e) => {
                        setShowEmail(true);
                        setGiftDetails({
                          ...giftDetails,
                          deliveryMethod: e.target.value,
                        });
                      }}
                    />
                    {showEmail && (
                      <Form.Group
                        className="mb-3"
                        controlId="giftForm.recipientEmail"
                      >
                        <Form.Control
                          required={showEmail}
                          type="name"
                          placeholder="Preferred Email"
                          onChange={(e) => {
                            setGiftDetails({
                              ...giftDetails,
                              recipientEmail: e.target.value,
                            });
                          }}
                        />
                      </Form.Group>
                    )}
                    <Form.Check
                      type="radio"
                      name="delivery-method"
                      label="You text (with a link we provide)"
                      value={1}
                      onChange={(e) => {
                        setShowEmail(false);
                        setGiftDetails({
                          ...giftDetails,
                          deliveryMethod: e.target.value,
                        });
                      }}
                    />
                  </Form.Group>
                </div>
                <div className="d-grid col-12">
                  <Button className="w-100" type="submit">
                    Add to bag
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </>
  );
};

export default SendAGift;
