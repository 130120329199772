import React from "react";
import { Helmet } from "react-helmet";
import ProfessionalContactForm from "./ProfessionalContactForm";

const Press = () => {
  return (
    <>
      <Helmet>
        <title>Press | Reyz</title>
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content="Click here to download logos, images and get in touch about featuring Reyz in the news."
        />
      </Helmet>
      <div style={{ alignContent: "center", textAlign: "center" }}>
        <h1>For the Press</h1>
        <section>
          <p>
            Please click on the following links to download digital versions of
            our Media Kit assets:
          </p>
          <ul style={{ listStyle: "none", padding: "0" }}>
            <li>
              <a
                style={{ color: "#221551" }}
                target="_blank"
                rel="noopener noreferrer"
                href="https://drive.google.com/drive/folders/1WAzpl2SbaDtPG94HjL1r4tp3vGjzKQfh?usp=sharing"
              >
                Main Logo
              </a>
            </li>
            <li>
              <a
                style={{ color: "#221551" }}
                target="_blank"
                rel="noopener noreferrer"
                href="https://drive.google.com/drive/folders/1YK97JADXz6jW_VTLCL_XkLTZgMrVZdl1?usp=sharing"
              >
                Reyz Y icon
              </a>
            </li>
          </ul>
        </section>
        <section>
          <p>Links to PDFs:</p>
          <ul style={{ listStyle: "none", padding: "0" }}>
            <li>
              <a
                style={{ color: "#221551" }}
                target="_blank"
                rel="noopener noreferrer"
                href="https://drive.google.com/file/d/1qDEV_OduPFpeJh33O4Z0OHDhR3YdBu_u/view?usp=sharing"
              >
                Our Story
              </a>
            </li>
            <li>
              <a
                style={{ color: "#221551" }}
                target="_blank"
                rel="noopener noreferrer"
                href="https://drive.google.com/file/d/1TzBbG3F7DcsDNwNccjpZe87PlG9WLC1S/view?usp=sharing"
              >
                Our Brand
              </a>
            </li>
            <li>
              <a
                style={{ color: "#221551" }}
                target="_blank"
                rel="noopener noreferrer"
                href="https://drive.google.com/file/d/1g-skxMLaW9Dt6Z_TaIenqsdzCefaZWxs/view?usp=sharing"
              >
                Reyz Comfort Bra
              </a>
            </li>
            <li>
              <a
                style={{ color: "#221551" }}
                target="_blank"
                rel="noopener noreferrer"
                href="https://drive.google.com/file/d/1PytTIPvWBoCT_Vj10JhpyRYD2Llvn9Qt/view?usp=sharing"
              >
                Partnerships
              </a>
            </li>
            <li>
              <a
                style={{ color: "#221551" }}
                target="_blank"
                rel="noopener noreferrer"
                href="https://drive.google.com/file/d/1msL-n22IC-NtmVaC-RmOQFcMNxZaWw6P/view?usp=sharing"
              >
                Our Reviews
              </a>
            </li>
          </ul>
        </section>

        <section>
          <p style={{ textAlign: "center" }}>
            If you would like to do a feature on Reyz or our founder, please use
            the form below.
          </p>
          <ProfessionalContactForm />
        </section>
      </div>
    </>
  );
};

export default Press;
