import React from "react";
import { Row, Col, Card, Alert, ListGroup } from "react-bootstrap";
import BagItem from "../BagItem";
import BagGiftItem from "../BagGiftItem";

const OrderSummary = (props) => {
  const bagItems = props.bagItems;
  const directPurchasesL = bagItems.directPurchases.length;
  const giftPackagesL = bagItems.giftPackages.length;

  return (
    <div style={{ marginBottom: "16px" }}>
      <Card>
        <Card.Header>
          <h3>Order Summary</h3>
        </Card.Header>
        <Card.Body>
          <Row>
            {bagItems.directPurchases.length === 0 &&
            bagItems.giftPackages.length === 0 ? (
              <Alert variant={"primary"}>Your bag is empty</Alert>
            ) : (
              <>
                {directPurchasesL > 0 ? <h3>For You</h3> : null}
                {bagItems.directPurchases.map((item) => (
                  <BagItem
                    key={item.whenAdded}
                    item={item}
                    showRemoveButton={false}
                  />
                ))}
                {giftPackagesL > 0 ? <h3>For Others</h3> : null}
                {bagItems.giftPackages.map((item) => (
                  <BagGiftItem
                    key={item.whenAdded}
                    item={item}
                    showRemoveButton={false}
                  />
                ))}
              </>
            )}
          </Row>
          <Row>
            <ListGroup variant="flush">
              <ListGroup.Item>
                <Col>Subtotal</Col>
                <Col>${props.prices.bagItemsPrice}</Col>
              </ListGroup.Item>
              <ListGroup.Item>
                <Col>Tax</Col>
                <Col>${props.prices.bagTaxPrice}</Col>
              </ListGroup.Item>
              <ListGroup.Item>
                <Col>Shipping</Col>
                <Col>${props.prices.bagShippingPrice}</Col>
              </ListGroup.Item>
              <ListGroup.Item>
                <Col>Total</Col>
                <Col>${props.prices.bagTotalPrice}</Col>
              </ListGroup.Item>
            </ListGroup>

            <hr />
          </Row>
        </Card.Body>
      </Card>
    </div>
  );
};

export default OrderSummary;
