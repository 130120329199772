import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";

const OrderConfirmation = ({ history, redemptionLinks }) => {
  useEffect(() => {
    document.title = "Order Confirmation";
  }, []);

  const goToLeaveFeedback = () => {
    history.push("/feedback");
  };

  return (
    <div style={{ textAlign: "center" }}>
      <h2>Best Supporting Hero</h2>
      <p>Your order is complete!</p>

      {redemptionLinks.redemptionLinks.length > 0 ? (
        <div>
          <p>
            Please use this link to text or email to your gift recipient (It
            will also be included in your order confirmation).
          </p>
          {redemptionLinks.redemptionLinks.map((link) => (
            <p>{link}</p>
          ))}
        </div>
      ) : null}

      <h4 style={{ marginTop: "24px" }}>Please Rate Your Experience</h4>
      <div>
        <FontAwesomeIcon
          icon={faStar}
          style={{ height: "64px", width: "64px" }}
          color="gold"
          onClick={goToLeaveFeedback}
        />
        <FontAwesomeIcon
          icon={faStar}
          style={{ height: "64px", width: "64px" }}
          color="gold"
          onClick={goToLeaveFeedback}
        />
        <FontAwesomeIcon
          icon={faStar}
          style={{ height: "64px", width: "64px" }}
          color="gold"
          onClick={goToLeaveFeedback}
        />
        <FontAwesomeIcon
          icon={faStar}
          style={{ height: "64px", width: "64px" }}
          color="gold"
          onClick={goToLeaveFeedback}
        />
        <FontAwesomeIcon
          icon={faStar}
          style={{ height: "64px", width: "64px" }}
          color="gold"
          onClick={goToLeaveFeedback}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    redemptionLinks: state.redemptionLinks,
  };
};

export default connect(mapStateToProps)(OrderConfirmation);
