import React from "react";
import Helmet from "react-helmet";
import ProfessionalContactForm from "./ProfessionalContactForm";

const PartnershipsAndCollabs = () => {
  return (
    <>
      <Helmet>
        <title>Partnerships & Collaborations | Reyz</title>
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content="We are actively seeking input and collaboration from experts in breast health. We want to innovate and create the best experiences for patients and women when it comes to products for overall breast health."
        />
      </Helmet>
      <div style={{ textAlign: "center" }}>
        <h1>Partnerships</h1>
        <p>
          We are always looking to innovate and design products that create a
          better experience for all women. We know that when we collaborate with
          others, we all win.
        </p>
        <section>
          <h2>PDFs</h2>
          <ul style={{ listStyle: "none", padding: "0" }}>
            <li>
              <a
                style={{ color: "#221551" }}
                target="_blank"
                rel="noopener noreferrer"
                href="https://drive.google.com/file/d/1PytTIPvWBoCT_Vj10JhpyRYD2Llvn9Qt/view?usp=sharing"
              >
                Partnerships
              </a>
            </li>
          </ul>
        </section>
        <p>
          If you are interested in partnering or collaborating with Reyz to
          drive change and advance products for all breast health, give us a
          shout!
        </p>
        <ProfessionalContactForm />
      </div>
    </>
  );
};

export default PartnershipsAndCollabs;
