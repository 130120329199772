import Button from "react-bootstrap/Button";
import React from "react";
import { Row, Col, Image } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const BagItem = (props) => {
  return (
    <div>
      <Row
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {/* Remove Item */}
        <Col lg={1} md={1} xs={2}>
          {props.showRemoveButton && (
            <Button
              type="button"
              variant="light"
              onClick={() =>
                props.removeFromBagHandler(
                  props.item.whenAdded,
                  props.item.purchaseType
                )
              }
              style={{
                backgroundColor: "transparent",
                borderColor: "transparent",
              }}
            >
              <FontAwesomeIcon icon={faTimes} />
            </Button>
          )}
        </Col>
        {/* Item Image */}
        <Col lg={3} md={2} xs={2}>
          <Image src={props.item.braImage} fluid></Image>
        </Col>
        {/* Item Name */}
        <Col lg={5} md={6} xs={5}>
          <ul style={{ listStyleType: "none" }}>
            <li>
              <h6>{props.item.braName}</h6>
            </li>
            <li>Color: {props.item.braColor}</li>
            <li>Size: {props.item.braSize}</li>
          </ul>
        </Col>
        {/* Item Price */}
        <Col lg={3} md={3} xs={3}>
          ${props.item.braPrice}
        </Col>
      </Row>
      <hr />
    </div>
  );
};

export default BagItem;
