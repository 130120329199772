import {
  GET_BRA_INFO_REQUEST,
  GET_BRA_INFO_SUCCESS,
  GET_BRA_INFO_FAIL,
} from "./braTypes";
import axios from "axios";

export const getBraInfo = () => async (dispatch) => {
  try {
    dispatch({ type: GET_BRA_INFO_REQUEST });

    const { data } = await axios.get("/api/directPurchase/items/bras");
    dispatch({
      type: GET_BRA_INFO_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_BRA_INFO_FAIL,
      payload: error.message,
    });
  }
};
