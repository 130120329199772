import tempHeroImg from "../../assets/images/bra-images/HeroImagePrimaryBlackFront.webp";
import {
  GET_BRA_INFO_REQUEST,
  GET_BRA_INFO_SUCCESS,
  GET_BRA_INFO_FAIL,
} from "./braTypes";

const initialState = {
  productName: "Comfort Bra",
  productImage: tempHeroImg,
  subProducts: [],
  loading: false,
};

const braReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_BRA_INFO_REQUEST:
      return { ...state, loading: true };
    case GET_BRA_INFO_SUCCESS:
      return {
        ...state,
        productPrice: action.payload[0].sales_rate,
        loading: false,
        subProducts: action.payload,
      };
    case GET_BRA_INFO_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default braReducer;
