import {
  GET_GIFT_ITEMS_INFO_REQUEST,
  GET_GIFT_ITEMS_INFO_SUCCESS,
  GET_GIFT_ITEMS_INFO_FAIL,
} from "./giftItemsTypes";

const initialState = {
  giftItemsInfo: {
    giftBoxPrice: 0,
    giftBoxId: 0,
    wellnessBundlePrice: 0,
    wellnessBundleId: 0,
  },
  loading: false,
};

const braReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_GIFT_ITEMS_INFO_REQUEST:
      return { ...state, loading: true };
    case GET_GIFT_ITEMS_INFO_SUCCESS:
      return {
        giftItemsInfo: {
          giftBoxPrice: action.payload.giftBoxInfo.item.sales_rate,
          giftBoxId: action.payload.giftBoxInfo.item.item_id,
          wellnessBundlePrice:
            action.payload.wellnessBundleInfo.item.sales_rate,
          wellnessBundleId: action.payload.wellnessBundleInfo.item.item_id,
        },
        loading: false,
      };
    case GET_GIFT_ITEMS_INFO_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default braReducer;
