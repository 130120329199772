import React, { useState } from "react";
import { Form, Row, Col, Button, Toast } from "react-bootstrap";

const SelectPackage = (props) => {
  const [showWarning, setShowWarning] = useState(false);
  const giftBoxId = props.giftItemsInfo.giftBoxId;
  const giftBoxPrice = props.giftItemsInfo.giftBoxPrice;
  const wellnessBundleId = props.giftItemsInfo.wellnessBundleId;
  const wellnessBundlePrice = props.giftItemsInfo.wellnessBundlePrice;

  const onSubmit = () => {
    let selectedItems = [];
    const items = document.getElementsByName("gift-package-item");
    for (let i = 0; i < items.length; i++) {
      if (items[i].checked) {
        let price = 0;
        if (items[i].id === giftBoxId) {
          price = giftBoxPrice;
        } else if (items[i].id === wellnessBundleId) {
          price = wellnessBundlePrice;
        }
        selectedItems.push({
          id: items[i].id,
          value: items[i].value,
          price: price,
        });
      }
    }

    if (selectedItems.length === 0) {
      setShowWarning(true);
    } else {
      props.selectPackageOnClickHandler(selectedItems);
    }
  };

  return (
    <>
      <h2>Select Package Items</h2>
      <Row>
        <Col xl={8} lg={8} md={8} sm={8}>
          <Form.Check
            type="checkbox"
            name="gift-package-item"
            id={giftBoxId}
            label={`Gift Box (includes 1 Comfort Bra) - $${giftBoxPrice}`}
            value={`Gift Box (includes 1 Comfort Bra) - $${giftBoxPrice}`}
            checked
            readOnly
          />
          <Form.Check
            type="checkbox"
            name="gift-package-item"
            id={wellnessBundleId}
            label={`Wellness Bundle Add-on (includes Eye Pillow & Celestine Crystal) - $${wellnessBundlePrice}`}
            value={`Wellness Bundle Add-on (includes Eye Pillow & Celestine Crystal) - $${wellnessBundlePrice}`}
          />
        </Col>
        <Col xl={4} lg={4} md={4} sm={4}>
          <Toast
            className="w-100"
            onClose={() => setShowWarning(false)}
            show={showWarning}
            delay={5000}
            autohide
            bg="warning"
          >
            <Toast.Header>
              <strong className="me-auto">Oops!</strong>
            </Toast.Header>
            <Toast.Body>Please select gift items.</Toast.Body>
          </Toast>
          <Button className="w-100" onClick={() => onSubmit()}>
            Continue
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default SelectPackage;
