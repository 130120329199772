import React, { useEffect, useRef, useState, useCallback } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { getBraInfo, addBraToBag } from "../redux";
import { Button, Col, Container, Row } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import Testimonials from "../components/Testimonials";
import SizeGuideModal from "../components/SizeGuideModal";
import SizeRadioGroup from "../components/SizeRadioGroup";
import Loader from "../components/Loader";
import Message from "../components/Message";

import ImageGallery from "react-image-gallery";
import detailsImg1 from "../assets/images/homepage-details/KBM04376.webp";
import detailsImg2 from "../assets/images/homepage-details/bra_details2.webp";

import fitImg from "../assets/images/brand-product-icons/fit.webp";
import functionalImg from "../assets/images/brand-product-icons/functional.webp";
import designImg from "../assets/images/brand-product-icons/design.webp";

import comfortBraBlack from "../assets/images/bra-images/Product Page Black - Front Outside-03900-2-sq.webp";
import comfortBraNude from "../assets/images/bra-images/Product Page Nude - Front Outside Nude-03869 copy-sq.webp";

const Product = (props) => {
  const braInfoLoaded = useRef(false);
  const { getBraInfo } = props;
  const { subProducts } = props;
  const [inStockItemSelected, setInStockItemSelected] = useState(false);
  const [itemSelected, setItemSelected] = useState(false);
  const [selectedItemInfo, setSelectedItemInfo] = useState({
    selectedItemPrice: 55.0,
    productImage: "",
    selectedColor: "",
    selectedSize: "",
    selectedItemId: "",
  });
  const redeemEGift = props.redeemEGift;

  useEffect(() => {
    if (redeemEGift) {
      document.title = "Get Your Gift!";
    } else {
      document.title = "Shop | Reyz";
    }
  }, [redeemEGift]);

  const selectedItemValidator = useCallback(
    (color, size) => {
      const item = subProducts.find(
        (i) =>
          ((i.attribute_option_name1 === size ||
            i.attribute_option_name1 === size.toLowerCase()) &&
            (i.attribute_option_name2 === color ||
              i.attribute_option_name2 === color.toLowerCase())) ||
          ((i.attribute_option_name1 === color ||
            i.attribute_option_name1 === color.toLowerCase()) &&
            (i.attribute_option_name2 === size ||
              i.attribute_option_name2 === size.toLowerCase()))
      );

      if (item != null) {
        setItemSelected(true);
        if (item.actual_available_for_sale_stock < 1) {
          setInStockItemSelected(false);
        } else {
          let braImage = "";
          if (color === "Nude") {
            braImage = comfortBraNude;
          } else if (color === "Black") {
            braImage = comfortBraBlack;
          }
          setSelectedItemInfo((p) => {
            return {
              ...p,
              productImage: braImage,
              selectedItemPrice: item.sales_rate,
              selectedItemId: item.item_id,
            };
          });
          setInStockItemSelected(true);
        }
      }
    },
    [subProducts]
  );

  useEffect(() => {
    if (!braInfoLoaded.current) {
      getBraInfo();
      braInfoLoaded.current = true;
    } else {
      selectedItemValidator(
        selectedItemInfo.selectedColor,
        selectedItemInfo.selectedSize
      );
    }
  }, [
    getBraInfo,
    selectedItemInfo.selectedColor,
    selectedItemInfo.selectedSize,
    selectedItemValidator,
  ]);

  const onItemSelectedHandler = (e) => {
    if (e.target.name === "color") {
      setSelectedItemInfo({
        ...selectedItemInfo,
        selectedColor: e.target.value,
      });
    } else if (e.target.name === "size") {
      setSelectedItemInfo({
        ...selectedItemInfo,
        selectedSize: e.target.value,
      });
    }
  };

  const addToBagHandler = () => {
    const whenAdded = new Date();
    const chosenBra = {
      productName: props.productName,
      productPrice: selectedItemInfo.selectedItemPrice,
      productImage: selectedItemInfo.productImage,
      selectedItemId: selectedItemInfo.selectedItemId,
      quantity: 1,
      productColor: selectedItemInfo.selectedColor,
      productSize: selectedItemInfo.selectedSize,
      whenAdded: whenAdded,
      purchaseType: "DIRECT",
    };

    if (props.redeemEGift) {
      props.selectBraOnClickHandler(chosenBra);
    } else {
      props.addBraToBag(chosenBra);
      props.history.push("/bag");
    }
  };

  return (
    <>
      <Helmet>
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content="Shop for comfortable Bras that aid in recovery from surgery, radiation and daily skin irritations."
        />
      </Helmet>
      {/* Product Info */}
      <section>
        <Container>
          <Row>
            <Col xl={{ offset: "0", span: "7" }} md={6} sm={4}>
              <ImageGallery
                items={[
                  {
                    original:
                      selectedItemInfo.selectedColor === "Nude"
                        ? comfortBraNude
                        : comfortBraBlack,
                    thumbnail:
                      selectedItemInfo.selectedColor === "Nude"
                        ? comfortBraNude
                        : comfortBraBlack,
                  },
                  {
                    original: detailsImg1,
                    thumbnail: detailsImg1,
                  },
                  {
                    original: detailsImg2,
                    thumbnail: detailsImg2,
                  },
                ]}
                className="image-gallery"
                showFullscreenButton={false}
                showNav={false}
                showPlayButton={false}
                style={{ borderColor: "#000" }}
              />
            </Col>
            <Col xl={{ offset: "1", span: "4" }} md={4} sm={4}>
              <h2>{props.productName}</h2>
              <strong>${selectedItemInfo.selectedItemPrice}</strong>
              <h5>Product Details</h5>
              <ul>
                <li>Knitted for maximum comfort & durability</li>
                <li>Seamless and wire-free </li>
                <li>88% Microfiber nylon, 12% Spandex Lycra</li>
                <li>Sustainably Made in USA</li>
              </ul>

              {props.loading ? (
                <Loader message="Loading colors and sizes!" />
              ) : props.error ? (
                <Message variant="danger">{props.error}</Message>
              ) : (
                <>
                  {" "}
                  {/* Color and Size selection */}
                  <label>color</label>
                  <div className="colors" style={{ marginBottom: "24px" }}>
                    <label>
                      <input
                        type="radio"
                        name="color"
                        value="Nude"
                        onClick={onItemSelectedHandler}
                      />
                      <span
                        className="swatch"
                        style={{ backgroundColor: "#C5AE91" }}
                      ></span>
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="color"
                        value="Black"
                        onClick={onItemSelectedHandler}
                      />
                      <span
                        className="swatch"
                        style={{ backgroundColor: "#000" }}
                      ></span>
                    </label>
                  </div>
                  <label>size</label>
                  <SizeGuideModal />
                  <SizeRadioGroup
                    onItemSelectedHandler={onItemSelectedHandler}
                  />
                  <div
                    className="d-grid col-12"
                    style={{ marginBottom: "8px" }}
                  >
                    {!itemSelected && <p>Please select a size and color.</p>}
                    {!inStockItemSelected && itemSelected && (
                      <p>
                        Sorry, this item is out of stock in the selected color
                        and size.
                      </p>
                    )}
                    <Button
                      className="w-100"
                      onClick={addToBagHandler}
                      disabled={!inStockItemSelected}
                    >
                      Add to Bag
                    </Button>
                  </div>
                  {!props.redeemEGift && (
                    <div>
                      <h6>payment methods</h6>
                      <ul>
                        <li>PayPal</li>
                        <li>Credit Card</li>
                      </ul>
                      <Button
                        style={{
                          textTransform: "none",
                          color: "#221551",
                          padding: "0px 0px 0px 0px",
                          fontSize: "16px",
                          display: "block",
                        }}
                        variant="link"
                        onClick={() => props.history.push("/faqs")}
                      >
                        FAQs
                      </Button>
                      <Button
                        style={{
                          textTransform: "none",
                          color: "#221551",
                          padding: "0px 0px 0px 0px",
                          fontSize: "16px",
                          display: "block",
                        }}
                        variant="link"
                        onClick={() => props.history.push("/send-a-gift")}
                      >
                        Looking to give a gift?
                      </Button>
                    </div>
                  )}
                </>
              )}
            </Col>
          </Row>
        </Container>
      </section>

      {/* Product Benefits */}
      <section
        style={{
          textAlign: "center",
          paddingTop: "100px",
          paddingBottom: "100px",
          fontSize: "1.5rem",
        }}
      >
        <Container>
          <Row>
            <Col xl={4} md={4} sm={4}>
              <Image
                src={fitImg}
                style={{ width: 150, height: 150, margin: "4px" }}
                alt="fit"
                loading="lazy"
              />
              <h2>Fit</h2>
              <p>comfortable, wide shoulder, smooth silhouette</p>
            </Col>
            <Col xl={4} md={4} sm={4}>
              <Image
                src={functionalImg}
                style={{ width: 150, height: 150, margin: "4px" }}
                alt="functional"
                loading="lazy"
              />
              <h2>Functional</h2>
              <p>skin-friendly fabric supports ice packs</p>
            </Col>
            <Col xl={4} md={4} sm={4}>
              <Image
                src={designImg}
                style={{ width: 150, height: 150, margin: "4px" }}
                alt="design"
                loading="lazy"
              />
              <h2>Design</h2>
              <p>single layer, quality yarn, seamless knit</p>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Testimonials */}
      <section>
        <Testimonials color="#fff" />
        <div style={{ height: "2px" }} />
      </section>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    productName: state.bra.productName,
    productImage: state.bra.productImage,
    subProducts: state.bra.subProducts,
    loading: state.bra.loading,
    error: state.bra.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBraInfo: () => dispatch(getBraInfo()),
    addBraToBag: (chosenBra) => dispatch(addBraToBag(chosenBra)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Product);
