import React, { useState } from "react";
import {
  Toast,
  Card,
  Form,
  Button,
  InputGroup,
  FormControl,
} from "react-bootstrap";

const PromoCard = (props) => {
  const promoCode = "temp";
  let toastMessage = "Promo code not recognized. Please check and try again.";
  const [show, setShow] = useState(false);

  const handleSubmit = () => {
    const code = document.getElementById("promo-code").value.toLowerCase();
    let percent;
    let valid = false;
    if (code === "joy") {
      percent = 0.8;
      valid = true;
    } else if (code === "aware") {
      percent = 0.9;
      valid = true;
    } else if (code === "cool40") {
      console.log("num items: ", props.numItems);
      if (props.numItems == 1) {
        percent = 0.0;
        valid = true;
      } else {
        toastMessage = "Sorry, this code is only redeemable for one bra.";
      }
    } else if (code === "hope") {
      if (props.numItems >= 2) {
        percent = 0.8;
        valid = true;
      } else {
        toastMessage =
          "Sorry, this code is only redeemable when purchasing 2 or more bras.";
      }
    }

    if (valid) {
      props.applyPromoCodeHandler(percent);
    } else {
      setShow(true);
    }
  };

  return (
    <>
      <Toast
        className="w-100"
        onClose={() => setShow(false)}
        show={show}
        delay={5000}
        autohide
        bg="warning"
      >
        <Toast.Body>{toastMessage}</Toast.Body>
      </Toast>
      <Card style={{ marginBottom: "16px" }}>
        <Card.Header>Enter Your Promo Code!</Card.Header>
        <Card.Body>
          <Form>
            <InputGroup>
              <FormControl
                placeholder="Enter Your Promo Code!"
                id="promo-code"
              />
              <Button onClick={handleSubmit}>apply</Button>
            </InputGroup>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};

export default PromoCard;
