import {
  BAG_ADD_BRA,
  BAG_ADD_GIFT,
  BAG_APPLY_PROMO_CODE,
  BAG_REMOVE_ITEM,
  BAG_SAVE_CUSTOMER_EMAIL,
  BAG_SAVE_SHIPPING_INFO,
  SET_BAG_TO_EMPTY_STATE,
} from "./bagTypes";

const initialBagItems = localStorage.getItem("bagItems")
  ? JSON.parse(localStorage.getItem("bagItems"))
  : { directPurchases: [], giftPackages: [] };

const initialBagShippingInfo = localStorage.getItem("bagShippingInfo")
  ? JSON.parse(localStorage.getItem("bagShippingInfo"))
  : {};

const initialBagCustomerEmail = localStorage.getItem("bagCustomerEmail")
  ? JSON.parse(localStorage.getItem("bagCustomerEmail"))
  : "";

const initialPromoApplied = localStorage.getItem("promoApplied")
  ? JSON.parse(localStorage.getItem("promoApplied"))
  : false;

const initialRedeemingEGiftInfo = {
  selectedBra: {},
};

const initialState = {
  bagItems: initialBagItems,
  bagShippingInfo: initialBagShippingInfo,
  bagCustomerEmail: initialBagCustomerEmail,
  promoApplied: initialPromoApplied,
  percent: 1.0,

  // Redeeming Gift
  redeemingGift: initialRedeemingEGiftInfo,
};

const bagReducer = (state = initialState, action) => {
  switch (action.type) {
    case BAG_ADD_BRA:
      let item = action.payload;
      if (state.promoApplied) {
        const updatedPrice = Number(
          (Math.round(item.braPrice * state.percent * 100) / 100).toFixed(2)
        );
        item = { ...item, braPrice: updatedPrice };
      }
      localStorage.setItem(
        "bagItems",
        JSON.stringify({
          ...state.bagItems,
          directPurchases: [...state.bagItems.directPurchases, item],
        })
      );
      return {
        ...state,
        bagItems: {
          ...state.bagItems,
          directPurchases: [...state.bagItems.directPurchases, item],
        },
      };
    case BAG_ADD_GIFT:
      let gift = action.payload;
      if (state.promoApplied) {
        const updatedPrice = Number(
          (Math.round(gift.packagePrice * state.percent * 100) / 100).toFixed(2)
        );
        gift = { ...gift, packagePrice: updatedPrice };
      }
      localStorage.setItem(
        "bagItems",
        JSON.stringify({
          ...state.bagItems,
          giftPackages: [...state.bagItems.giftPackages, gift],
        })
      );
      return {
        ...state,
        bagItems: {
          ...state.bagItems,
          giftPackages: [...state.bagItems.giftPackages, gift],
        },
      };
    case BAG_REMOVE_ITEM:
      if (action.payload.purchaseType === "DIRECT") {
        let updatedDirectPurchaseItems = state.bagItems.directPurchases.filter(
          (item) => item.whenAdded !== action.payload.whenAdded
        );
        localStorage.setItem(
          "bagItems",
          JSON.stringify({
            ...state.bagItems,
            directPurchases: updatedDirectPurchaseItems,
          })
        );
        return {
          ...state,
          bagItems: {
            ...state.bagItems,
            directPurchases: updatedDirectPurchaseItems,
          },
        };
      } else if (action.payload.purchaseType === "GIFT") {
        let updatedGiftPackagesItems = state.bagItems.giftPackages.filter(
          (item) => item.whenAdded !== action.payload.whenAdded
        );
        localStorage.setItem(
          "bagItems",
          JSON.stringify({
            ...state.bagItems,
            giftPackages: updatedGiftPackagesItems,
          })
        );
        return {
          ...state,
          bagItems: {
            ...state.bagItems,
            giftPackages: updatedGiftPackagesItems,
          },
        };
      }
      break;
    case BAG_SAVE_SHIPPING_INFO:
      const bagShippingInfo = action.payload;
      localStorage.setItem("bagShippingInfo", JSON.stringify(bagShippingInfo));
      return {
        ...state,
        bagShippingInfo: bagShippingInfo,
      };
    case BAG_SAVE_CUSTOMER_EMAIL:
      const bagCustomerEmail = action.payload;
      localStorage.setItem(
        "bagCustomerEmail",
        JSON.stringify(bagCustomerEmail)
      );
      return {
        ...state,
        bagCustomerEmail: bagCustomerEmail,
      };
    case SET_BAG_TO_EMPTY_STATE:
      // clear local storage
      localStorage.clear();
      return {
        bagItems: { directPurchases: [], giftPackages: [] },
        bagShippingInfo: {},
        bagCustomerEmail: "",
        promoApplied: false,
      };
    case BAG_APPLY_PROMO_CODE:
      const percent = action.payload;
      let updatedDirects = [];
      state.bagItems.directPurchases.forEach((item) => {
        const price = Number(
          (Math.round(item.braPrice * percent * 100) / 100).toFixed(2)
        );
        updatedDirects.push({
          ...item,
          braPrice: price,
        });
      });
      let updatedGifts = [];
      state.bagItems.giftPackages.forEach((item) => {
        const price = Number(
          (Math.round(item.packagePrice * percent * 100) / 100).toFixed(2)
        );
        updatedGifts.push({
          ...item,
          packagePrice: price,
        });
      });
      localStorage.setItem(
        "bagItems",
        JSON.stringify({
          giftPackages: updatedGifts,
          directPurchases: updatedDirects,
        })
      );
      localStorage.setItem(
        "promoApplied",
        JSON.stringify({
          promoApplied: true,
        })
      );
      return {
        ...state,
        promoApplied: true,
        percent: percent,
        bagItems: {
          giftPackages: updatedGifts,
          directPurchases: updatedDirects,
        },
      };
    default:
      return state;
  }
};

export default bagReducer;
