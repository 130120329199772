import React, { useState } from "react";
import { Button, Container, Row } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import Table from "react-bootstrap/Table";
// import SizeRadioGroup from "./SizeRadioGroup";
// import ImageSlider from "./ImageSlider";

const SizeGuideModal = () => {
  const [show, setShow] = useState(false);
  return (
    <>
      <Button variant="link" onClick={() => setShow(true)}>
        Find Your Size!
      </Button>

      <Modal
        show={show}
        onHide={() => setShow(false)}
        dialogClassName="modal-90w"
        aria-labelledby="size-guide-title"
        scrollable={true}
      >
        <Modal.Header closeButton>
          <Modal.Title id="size-guide-title">Size Guide</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SizeGuide />
        </Modal.Body>
      </Modal>
    </>
  );
};

function SizeGuide(props) {
  return (
    <Container>
      {/* <Row>
        <Col>
          <SizeRadioGroup />
        </Col>
        <Col>
          <ImageSlider />
        </Col>
      </Row> */}
      <Row>
        <SizeGuideTable />
        <p>
          Note: Due to the knitted design, the garment accommodates broad body
          and cup sizes. Please contact us for any questions or feedback on
          sizing.
        </p>
      </Row>
    </Container>
  );

  function SizeGuideTable() {
    return (
      <Table bordered responive={"lg"}>
        <thead>
          <tr>
            <th>Size</th>
            <th>
              US <br /> Size
            </th>
            <th>
              Weight
              <br />
              (Lbs)
            </th>
            <th>
              Bust
              <br />
              (inches)
            </th>
            <th>Cup</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>S/M</td>
            <td>0-6</td>
            <td>90-120</td>
            <td>33-36</td>
            <td>A-F</td>
          </tr>
          <tr>
            <td>M/L</td>
            <td>8-12</td>
            <td>110-140</td>
            <td>37-41</td>
            <td>A-H</td>
          </tr>
          <tr>
            <td>L/XL</td>
            <td>14-18</td>
            <td>130-180</td>
            <td>42-45</td>
            <td>A-H</td>
          </tr>
          <tr>
            <td>XL/2X</td>
            <td>20-24</td>
            <td>180-250</td>
            <td>46-50</td>
            <td>A-H</td>
          </tr>
        </tbody>
      </Table>
    );
  }
}

export default SizeGuideModal;
