import React, { useState } from "react";
import { PayPalButton } from "react-paypal-button-v2";
import { Button, Card, Form, Row, Col } from "react-bootstrap";
import axios from "axios";

const PaymentCard = (props) => {
  const prices = props.prices;
  const shippingInfo = props.shippingInfo;
  const paypalItems = props.paypalItems;
  const useShipping = props.useShipping;
  const [billingInfo, setBillingInfo] = useState({
    firstName: "",
    lastName: "",
    city: "",
    state: "",
    address: "",
    zip: "",
    cardNumber: "",
    expirationDate: "",
    ccv: "",
  });

  return (
    <Card>
      <Card.Header>Review Order & Purchase</Card.Header>
      {props.checkoutProcess.emailSubmitted &&
        props.checkoutProcess.shippingInfoSubmitted &&
        !props.checkoutProcess.paymentAuthorized && (
          <Card.Body>
            <Card.Text>
              Please review your information and purchase when you are ready.
            </Card.Text>
            <div className="d-grid col-12" style={{ marginBottom: "8px" }}>
              <PayPalButton
                createOrder={(data, actions) => {
                  if (!useShipping) {
                    return actions.order.create({
                      purchase_units: [
                        {
                          amount: {
                            currency_code: "USD",
                            value: prices.bagTotalPrice,
                            breakdown: {
                              item_total: {
                                currency_code: "USD",
                                value: prices.bagItemsPrice,
                              },
                              shipping: {
                                currency_code: "USD",
                                value: prices.bagShippingPrice,
                              },
                              tax_total: {
                                currency_code: "USD",
                                value: prices.bagTaxPrice,
                              },
                            },
                          },
                          items: paypalItems,
                        },
                      ],
                      application_context: {
                        shipping_preference: "NO_SHIPPING", // default is "GET_FROM_FILE"
                      },
                    });
                  } else {
                    return actions.order.create({
                      purchase_units: [
                        {
                          amount: {
                            currency_code: "USD",
                            value: prices.bagTotalPrice,
                            breakdown: {
                              item_total: {
                                currency_code: "USD",
                                value: prices.bagItemsPrice,
                              },
                              shipping: {
                                currency_code: "USD",
                                value: prices.bagShippingPrice,
                              },
                              tax_total: {
                                currency_code: "USD",
                                value: prices.bagTaxPrice,
                              },
                            },
                          },
                          items: paypalItems,
                          shipping: {
                            name: {
                              full_name: `${shippingInfo.firstName} ${shippingInfo.lastName}`,
                            },
                            type: "SHIPPING",
                            address: {
                              address_line_1: shippingInfo.addressOne,
                              address_line_2: shippingInfo.addressTwo,
                              admin_area_2: shippingInfo.city,
                              admin_area_1: shippingInfo.state,
                              postal_code: shippingInfo.zip,
                              country_code: "US",
                            },
                          },
                        },
                      ],
                      application_context: {
                        shipping_preference: "SET_PROVIDED_ADDRESS", // default is "GET_FROM_FILE"
                      },
                    });
                  }
                }}
                onApprove={(data, actions) => {
                  // Authorize the transaction
                  actions.order
                    .authorize()
                    .then(function (authorization) {
                      // Get the authorization id
                      var authorizationID =
                        authorization.purchase_units[0].payments
                          .authorizations[0].id;

                      props.authorizationApprovedHandler(authorizationID);
                    })
                    .catch((error) => {
                      props.setErrorAlert({
                        show: true,
                        message: error.message,
                      });
                    });
                }}
              />
            </div>
            {/* <div style={{ textAlign: "center" }}>
              <hr></hr>
              <h2>Or</h2>
              <hr></hr>
            </div>
            <h3>Billing Information</h3> */}
            {/* <div>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  props.cardInfoSubmitted(billingInfo);
                }}
              >
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formGridBFName">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      required
                      type="firstname"
                      placeholder="first"
                      value={billingInfo.firstName}
                      onChange={(e) =>
                        setBillingInfo({
                          ...billingInfo,
                          firstName: e.target.value,
                        })
                      }
                    />
                  </Form.Group>

                  <Form.Group as={Col} controlId="formGridBLName">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      required
                      type="lastname"
                      placeholder="last"
                      value={billingInfo.lastName}
                      onChange={(e) =>
                        setBillingInfo({
                          ...billingInfo,
                          lastName: e.target.value,
                        })
                      }
                    />
                  </Form.Group>
                </Row>
                <Form.Group className="mb-3" controlId="formCardNumber">
                  <Form.Label>Card Number</Form.Label>
                  <Form.Control
                    required
                    placeholder=""
                    value={billingInfo.cardNumber}
                    onChange={(e) =>
                      setBillingInfo({
                        ...billingInfo,
                        cardNumber: e.target.value,
                      })
                    }
                  />
                  <Row className="mb-3">
                    <Form.Group as={Col} controlId="formExpirationDate">
                      <Form.Label>Expiration Date</Form.Label>
                      <Form.Control
                        required
                        type="number"
                        placeholder="MMYR"
                        value={billingInfo.expirationDate}
                        onChange={(e) =>
                          setBillingInfo({
                            ...billingInfo,
                            expirationDate: e.target.value,
                          })
                        }
                      />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridBLName">
                      <Form.Label>CCV</Form.Label>
                      <Form.Control
                        required
                        type="number"
                        placeholder="ccv"
                        value={billingInfo.ccv}
                        onChange={(e) =>
                          setBillingInfo({
                            ...billingInfo,
                            ccv: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                  </Row>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formGridAddress1">
                  <Form.Label>Billing Address</Form.Label>
                  <Form.Control
                    required
                    placeholder="1234 Main St"
                    value={billingInfo.address}
                    onChange={(e) =>
                      setBillingInfo({
                        ...billingInfo,
                        address: e.target.value,
                      })
                    }
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="formGridCountry">
                  <Form.Label>Country</Form.Label>
                  <Form.Control
                    required
                    placeholder="country"
                    value={"US"}
                    readOnly={true}
                  />
                </Form.Group>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formGridCity">
                    <Form.Label>City</Form.Label>
                    <Form.Control
                      required
                      placeholder="city"
                      value={billingInfo.city}
                      onChange={(e) =>
                        setBillingInfo({
                          ...billingInfo,
                          city: e.target.value,
                        })
                      }
                    />
                  </Form.Group>

                  <Form.Group as={Col} controlId="formGridState">
                    <Form.Label>State</Form.Label>
                    <Form.Control
                      required
                      placeholder="state"
                      value={billingInfo.state}
                      onChange={(e) =>
                        setBillingInfo({
                          ...billingInfo,
                          state: e.target.value,
                        })
                      }
                    />
                  </Form.Group>
                </Row>

                <Row>
                  <Form.Group as={Col} controlId="formGridZip">
                    <Form.Label>Zip</Form.Label>
                    <Form.Control
                      required
                      placeholder="12345"
                      value={billingInfo.zip}
                      onChange={(e) =>
                        setBillingInfo({
                          ...billingInfo,
                          zip: e.target.value,
                        })
                      }
                    />
                  </Form.Group>
                </Row>

                <div
                  className="d-grid col-12"
                  style={{ marginBottom: "8px", marginTop: "16px" }}
                >
                  <Button className="w-100" type="submit">
                    checkout
                  </Button>
                </div>
              </Form>
            </div> */}
          </Card.Body>
        )}
    </Card>
  );
};

export default PaymentCard;
