import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  InputGroup,
  FormControl,
  Form,
  Toast,
  Alert,
  Image,
} from "react-bootstrap";
import Button from "react-bootstrap/Button";

import wbeLogo from "../assets/images/certs/WBE_Seal_REVERSE_CMYK.webp";
import nmsdcLogo from "../assets/images/certs/imgNMSDC.webp";
import fbIcon from "../assets/images/social-icons/fb.webp";
import igIcon from "../assets/images/social-icons/IG.webp";
import liIcon from "../assets/images/social-icons/LI.webp";
import ttIcon from "../assets/images/social-icons/tiktok.webp";
import twitterIcon from "../assets/images/social-icons/twitter.webp";

const Footer = () => {
  const [emailSubmitted, setEmailSubmitted] = useState(false);

  const [show, setShow] = useState(false);
  const [alert, setAlert] = useState({ show: false, error: "" });

  const crmSubmitHandler = async () => {
    const email = document.getElementById("stay-connected").value;

    // eslint-disable-next-line
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (email.match(mailformat)) {
      setShow(false);

      try {
        // change to Klaviyo
        const body = JSON.stringify({
          customerEmail: email,
          list: "subscriber",
        });
        fetch("/api/klaviyo/createContact", {
          method: "post",
          headers: {
            "content-type": "application/json",
          },
          body: body,
        })
          .then((res) => console.log(res))
          .catch((err) => console.error("error:" + err));
        setEmailSubmitted(true);
      } catch (error) {
        setAlert({ show: true, error: error.message });
      }
    } else {
      setShow(true);
    }
  };

  return (
    <footer
      style={{
        paddingTop: "100px",
        paddingBottom: "100px",
        backgroundColor: "#221551",
        color: "#fff",
      }}
    >
      {alert.show && (
        <Alert
          variant="danger"
          onClose={() => setAlert({ ...alert, show: false })}
          dismissible
        >
          <Alert.Heading>Error</Alert.Heading>
          <p>{alert.message}</p>
        </Alert>
      )}
      <Container>
        <Row>
          <Col xl={2} md={2} sm={4} align="center">
            <div style={{ marginBottom: "40px" }}>
              <h5 style={{ color: "#fff" }}>Information</h5>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.termsfeed.com/live/5cb34b1e-36bc-48e4-ae84-9cbfe05a505d"
              >
                Privacy Policy
              </a>
              <br />
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.termsfeed.com/live/5953c258-fa2e-43f8-a9b0-e8a73d792331"
              >
                Refund & Return Policy
              </a>
              <br />
              <Link to="/about"> Our Story</Link>
              <br />
              <Link to="/faqs"> FAQs</Link>
              <br />
              <Link to="/in-the-news"> In the News</Link>
              <br />
              <Link to="/press"> Press</Link>
              <br />
              <Link to="/partnerships-and-collabs">
                Partnerships & Collaborations
              </Link>
            </div>
          </Col>

          <Col xl={3} md={3} sm={4} align="center">
            <div style={{ marginBottom: "40px" }}>
              <h5 style={{ color: "#fff" }}>Contact Us</h5>
              <strong>Phone: 313-922-7200</strong>
              <br />
              <strong>E-Mail: info@GiveReyz.com</strong>
            </div>
          </Col>
          <Col xl={3} md={3} sm={4} align="center">
            <div style={{ marginBottom: "40px" }}>
              <h5 style={{ color: "#fff" }}>Follow Us</h5>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.facebook.com/GiveReyz/"
              >
                <img
                  src={fbIcon}
                  style={{ width: 35, margin: "4px" }}
                  alt="fb-icon"
                  loading="lazy"
                />
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/givereyz/"
              >
                <img
                  src={igIcon}
                  style={{ width: 35, margin: "4px" }}
                  alt="ig-icon"
                  loading="lazy"
                />
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://vm.tiktok.com/ZMdbPhPoR/"
              >
                <img
                  src={ttIcon}
                  style={{ width: 35, margin: "4px" }}
                  alt="tt-icon"
                  loading="lazy"
                />
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.linkedin.com/company/reyz/mycompany/"
              >
                <img
                  src={liIcon}
                  style={{ width: 35, margin: "4px" }}
                  alt="li-icon"
                  loading="lazy"
                />
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://twitter.com/GiveReyz"
              >
                <img
                  src={twitterIcon}
                  style={{ width: 35, margin: "4px" }}
                  alt="twitter-icon"
                  loading="lazy"
                />
              </a>
              <div style={{ textAlign: "center" }}>
                <Image
                  src={wbeLogo}
                  style={{ height: "100px", marginTop: "16px" }}
                  loading="lazy"
                />
              </div>
              <div style={{ textAlign: "center" }}>
                <Image
                  src={nmsdcLogo}
                  style={{ height: "100px", marginTop: "16px" }}
                  loading="lazy"
                />
              </div>
            </div>
          </Col>
          <Col xl={4} md={4} sm={4} style={{ alignContent: "center" }}>
            <h5 style={{ color: "#fff" }}>Stay Connected</h5>
            {!emailSubmitted ? (
              <div>
                <Form>
                  <InputGroup>
                    <FormControl
                      placeholder="Enter Your Email Address"
                      id="stay-connected"
                    />
                    <Button onClick={crmSubmitHandler}>Subscribe</Button>
                  </InputGroup>
                </Form>
                <Toast
                  className="w-100"
                  onClose={() => setShow(false)}
                  show={show}
                  delay={5000}
                  autohide
                  bg="warning"
                >
                  <Toast.Header>
                    <strong className="me-auto">Oops!</strong>
                  </Toast.Header>
                  <Toast.Body>Please enter a correct email.</Toast.Body>
                </Toast>
              </div>
            ) : (
              <p>Thank you!</p>
            )}
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
