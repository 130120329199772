import React, { useEffect } from "react";

const FeedbackConfirmation = () => {
  useEffect(() => {
    document.title = "Review Confirmation";
  }, []);

  return (
    <div style={{ textAlign: "center" }}>
      <h2>Thank You</h2>
      <p>We have recieved your feedback!</p>
    </div>
  );
};

export default FeedbackConfirmation;
