import React, { useEffect } from "react";
import { connect } from "react-redux";
import { removeFromBag } from "../redux";
import { Row, Col, Button, Alert } from "react-bootstrap";
import BagItem from "../components/BagItem";
import BagGiftItem from "../components/BagGiftItem";
import PromoCard from "../components/checkout/PromoCard";
import { applyPromoCode } from "../redux";

const Bag = (props) => {
  useEffect(() => {
    document.title = "Your Bag";
  }, []);

  const bagItems = props.bagItems;
  const promoApplied = props.promoApplied;
  const directPurchasesL = bagItems.directPurchases.length;
  const giftPackagesL = bagItems.giftPackages.length;

  const checkoutHandler = () => {
    props.history.push("/checkout");
  };

  const removeFromBagHandler = (whenAdded, purchaseType) => {
    props.removeFromBag(whenAdded, purchaseType);
  };

  const applyPromoCodeHandler = (percent) => {
    props.applyPromoCode(percent);
  };

  return (
    <>
      <h2>Healing Begins</h2>
      <hr />
      <Row>
        {directPurchasesL === 0 && giftPackagesL === 0 ? (
          <Alert variant={"primary"}>Your bag is empty</Alert>
        ) : (
          <>
            {directPurchasesL > 0 ? <h3>For You</h3> : null}
            {bagItems.directPurchases.map((item) => (
              <BagItem
                key={item.whenAdded}
                item={item}
                showRemoveButton={true}
                removeFromBagHandler={removeFromBagHandler}
              />
            ))}
            {giftPackagesL > 0 ? <h3>For Others</h3> : null}
            {bagItems.giftPackages.map((item) => (
              <BagGiftItem
                key={item.whenAdded}
                item={item}
                showRemoveButton={true}
                removeFromBagHandler={removeFromBagHandler}
              />
            ))}
          </>
        )}
      </Row>
      <Row>
        <Col lg={{ span: 4, offset: 8 }} xs={{ span: 6, offset: 6 }}>
          <div style={{ textAlign: "right" }}>
            Subtotal: $
            {(
              bagItems.directPurchases.reduce(
                (acc, item) => acc + item.braPrice,
                0
              ) +
              bagItems.giftPackages.reduce(
                (acc, item) => acc + item.packagePrice,
                0
              )
            ).toFixed(2)}
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={{ span: 4, offset: 8 }} xs={{ span: 12 }}>
          <div className="d-grid col-12" style={{ marginBottom: "8px" }}>
            {promoApplied ? (
              <div style={{ textAlign: "right" }}>
                Promo code has been applied!
              </div>
            ) : (
              <PromoCard
                applyPromoCodeHandler={applyPromoCodeHandler}
                promoApplied={promoApplied}
                numItems={bagItems.directPurchases.length}
              />
            )}
            <Button
              className="w-100"
              disabled={directPurchasesL === 0 && giftPackagesL === 0}
              onClick={checkoutHandler}
            >
              Checkout
            </Button>
          </div>
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    bagItems: state.bag.bagItems,
    promoApplied: state.bag.promoApplied,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    removeFromBag: (whenAdded, purchaseType) =>
      dispatch(removeFromBag(whenAdded, purchaseType)),
    applyPromoCode: (percent) => dispatch(applyPromoCode(percent)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Bag);
