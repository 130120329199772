import React from "react";
import { LinkContainer } from "react-router-bootstrap";
import { Container, Navbar, Nav, NavDropdown } from "react-bootstrap";
import logo from "../assets/images/Reyz_logo.webp";
import bagLogo from "../assets/images/Shopping-Bag-icon.webp";

const Header = () => {
  return (
    <Navbar
      id="header"
      sticky="top"
      bg="dark"
      variant="dark"
      expand="lg"
      collapseOnSelect
    >
      <Container>
        <LinkContainer to="/">
          <Navbar.Brand>
            <img src={logo} style={{ width: 100 }} alt="Give Reyz" />
          </Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <LinkContainer to="/">
              <Nav.Link>Home</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/about">
              <Nav.Link>Our Story</Nav.Link>
            </LinkContainer>
            <NavDropdown
              title="Shop"
              id="shop-nav-dropdown"
              style={{ margin: "0px" }}
            >
              <LinkContainer to="/product">
                <Nav.Link>For You</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/send-a-gift">
                <Nav.Link>For Others</Nav.Link>
              </LinkContainer>
            </NavDropdown>
            <LinkContainer to="/feedback">
              <Nav.Link>Contact</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/bag">
              <Nav.Link>
                <img src={bagLogo} style={{ width: 25 }} alt="bag" />
              </Nav.Link>
            </LinkContainer>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
