import React from "react";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";
import SizeGuideModal from "../../components/SizeGuideModal";

const FAQs = () => {
  return (
    <>
      <Helmet>
        <title>FAQs | Reyz</title>
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content="Have questions? We have answer about Reyz and the Reyz comfort bra."
        />
      </Helmet>
      <div>
        <h1>FAQs</h1>

        <h2>How do I know what size to get?</h2>
        <div>
          <SizeGuideModal />
        </div>
        <br />

        <h2>Is the bra for anyone or breast cancer patients only?</h2>
        <div>
          <p>
            The bra is a comfort bra for anyone. While designed specifically
            with needs of breast cancer patients in mind, this is a bra for
            anyone seeking comfort and support.
          </p>
        </div>
        <br />

        <h2>What information do I need to send a Reyz gift?</h2>
        <div>
          <p>
            All you need is the recipient's phone number to text them about the
            gift or their email to send the ecard.
          </p>
        </div>
        <br />

        <h2>Do you accept Returns?</h2>
        <div>
          {/* add a tag */}
          <p>
            See our full policy{" "}
            <a
              style={{ color: "#221551" }}
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.termsfeed.com/live/5953c258-fa2e-43f8-a9b0-e8a73d792331"
            >
              here
            </a>
            .
          </p>
          <p>
            In order for the Goods to be eligible for a return, please make sure
            that:
          </p>
          <ul>
            <li>The Goods were purchased in the last 45 days.</li>
            <li>The Goods are in the original packaging.</li>
            <li>
              You are responsible for the cost and risk of returning the Goods
              to Us.
            </li>
          </ul>
        </div>
        <br />

        <h2>How do I become a partner?</h2>
        <div>
          <p>
            Send an email to info@givereyz.com or fill out the form{" "}
            <Link style={{ color: "#221551" }} to="/partnerships-and-collabs">
              here
            </Link>
            .
          </p>
        </div>
        <br />

        <h2>Can I order the eye mask on its own?</h2>
        <div>
          <p>
            At this time, the eye mask can only be purchased as part of the
            bundle.
          </p>
        </div>
        <br />

        <h2>Does the bra have a front clasp?</h2>
        <div>
          <p>
            The Reyz comfort bra is a one-piece pullover or can be stepped into
            for those with limited mobility.
          </p>
        </div>
        <br />
      </div>
    </>
  );
};

export default FAQs;
