import React from "react";
import { Row, Col, Image } from "react-bootstrap";
import Product from "../../Product";

const RedeemEGiftSelect = (props) => {
  const senderMessage = props.giftPackage.senderMessage;
  return (
    <>
      <h2>Redeem your gift.</h2>
      <Row>
        <Col xl={4} md={6} sm={4}>
          <Row>
            {senderMessage && !(senderMessage === "") && (
              <p>
                {senderMessage}
                <br />
                <br />- {props.giftPackage.senderName}
              </p>
            )}
          </Row>
          <Row>
            <p>
              Know you’ve got support and this bra is only one small part of it.
              <br />
              <br />
              This unique gift allows you to choose exactly what you need and
              was paid for by {props.giftPackage.senderName}.
            </p>
          </Row>
        </Col>
        {props.giftPackage.eCard && (
          <Col
            xl={{ span: 4, offset: 4 }}
            md={6}
            sm={4}
            style={{ textAlign: "center" }}
          >
            <Image
              src={props.giftPackage.eCard}
              style={{ height: 192, margin: "4px", horizontalAlign: "center" }}
              alt="eCard"
            />
          </Col>
        )}
      </Row>
      <Product
        redeemEGift={true}
        history={props.history}
        selectBraOnClickHandler={props.selectBraOnClickHandler}
      />
    </>
  );
};

export default RedeemEGiftSelect;
