import React, { useRef, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import emailjs from "emailjs-com";

const LeaveFeedback = ({ history }) => {
  const form = useRef();
  const sendEmail = async (e) => {
    e.preventDefault();

    try {
      const body = JSON.stringify({
        customerEmail: document.getElementById("feedbackForm.EmailInput").value,
        list: "subscriber",
      });
      fetch("/api/klaviyo/createContact", {
        method: "post",
        headers: {
          "content-type": "application/json",
        },
        body: body,
      })
        .then((res) => console.log(res))
        .catch((err) => console.error("error:" + err));

      await emailjs.sendForm(
        "service_342gspg",
        "template_1etl69w",
        form.current,
        "user_d8nqj2B3imsBkvM3lzTeV"
      );

      history.push("/feedback-confirmation");
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <Helmet>
        <title>Contact | Reyz</title>
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content="Contact Reyz to ask questions or leave feedback about your bra purchase. Share your thoughts on the Reyz comfort bra for sensitive skin."
        />
      </Helmet>
      <Container>
        <Row>
          <Col
            xl={{ span: 8, offset: 2 }}
            md={{ span: 8, offset: 2 }}
            sm={{ span: 4 }}
            style={{}}
          >
            <h1 align="center">Contact Us</h1>
            <Form ref={form} onSubmit={sendEmail}>
              <Form.Group className="mb-3" controlId="feedbackForm.NameInput">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  required
                  type="name"
                  placeholder="Name"
                  name="customer_name"
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="feedbackForm.EmailInput">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  required
                  type="email"
                  placeholder="Email"
                  name="customer_email"
                />
              </Form.Group>
              <Form.Select
                style={{ marginBottom: "16px" }}
                name="customer_type"
                required
              >
                <option>How do you know Reyz?</option>
                <option value="I purchased the bra for myself.">
                  I purchased the bra for myself.
                </option>
                <option value="I received the bra as a gift.">
                  I received the bra as a gift.
                </option>
                <option value="I sent someone the bra as a gift.">
                  I sent someone the bra as a gift.
                </option>
                <option value="Other">Other</option>
              </Form.Select>
              <Form.Group
                className="mb-3"
                controlId="feedbackForm.FeedbackTextarea"
              >
                <Form.Label>Comments or Questions?</Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder="Let us know how we can help!"
                  rows={3}
                  name="customer_feedback"
                  required
                />
              </Form.Group>

              <div className="d-grid col-12">
                <Button className="w-100" type="submit">
                  Submit Review
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default LeaveFeedback;
