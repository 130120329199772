import React from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col, Image } from "react-bootstrap";
import aboutImg from "../assets/images/about/IMG_9018.webp";

const About = () => {
  return (
    <>
      <Helmet>
        <title>Our Story | Reyz</title>
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content="Inspired to take action after watching her mother in law undergo radiation treatments for breast cancer, Reyz founder Tamika Mayes designed and created a comfort bra specifically for women with sensitive skin and needs."
        />
      </Helmet>
      <Container>
        <Row style={{ textAlign: "center" }}>
          <h1>Our Story</h1>
        </Row>
        <Row>
          <Col
            xl={{ span: 4, offset: 2 }}
            lg={{ span: 6, offset: 0 }}
            style={{ textAlign: "center", overflow: "" }}
          >
            <Image
              src={aboutImg}
              style={{ width: 356, height: 568, margin: "4px" }}
            />
          </Col>
          <Col
            xl={{ span: 4, offset: 0 }}
            lg={{ span: 6, offset: 0 }}
            style={{ textAlign: "center" }}
          >
            <p style={{ textAlign: "left", fontSize: "1.5rem" }}>
              In 2011, my mother-in-law was diagnosed with breast cancer. Our
              family had a range of emotions. Denial. Optimism. Fear.
              Helplessness. Not to forget the many emotions of our fearless
              matriarch, along with her physical pain and discomfort. Like many
              women going through treatment, she had a significant radiation
              burn. This required her to regularly hold ice packs or gel sheets
              against her body to soothe the skin, which impacted her ability to
              use her hands for daily tasks.
              <br />
            </p>
          </Col>
        </Row>
        <Row>
          <Col xl={{ span: 8, offset: 2 }} style={{ textAlign: "center" }}>
            <p style={{ textAlign: "left", fontSize: "1.5rem" }}>
              <br />
              Why should a breast cancer patient have to endure that frustration
              on top of everything else?
            </p>
          </Col>
        </Row>
        <Row>
          <Col xl={{ span: 8, offset: 2 }} style={{ textAlign: "center" }}>
            <p style={{ textAlign: "left", fontSize: "1.5rem" }}>
              Many brands in the market focus on comfortable bras but fail to
              address the bigger issue of overall breast health. This is when my
              journey with Reyz began. To create a comfortable AND functional
              bra for freedom of movement but also for overall breast health.
              Whether it is a specific life event or for day-to-day issues.
              <br />
              <br />
              We designed this bra specifically for women undergoing highly
              sensitive and painful situations. We are delighted that recovering
              and every day women alike found our bra most comfortable and
              suitable for their unique needs. We achieved this by knitting a
              bra in the U.S. with quality yarns, which provides better control
              over the details, improved performance, universal comfort, and
              sustainability.
              <br />
              <br />
              Reyz is committed to focusing on the details and sensitive needs
              to bring innovative, healthy products and services that support
              all women. We are also committed to helping the broader community
              improve the healing, recovery, and well-being of friends, family
              and loved ones.
              <br />
              <br />
              Thank you for your interest and support of Reyz. We see it as
              investing in health, innovation, and sustainability!
              <br />
              <br />
              With heart and soul,
              <br />
              <br />
              Tamika, Reyz Founder
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default About;
