import React from "react";
import { Row, Col, Card, Form, Button, Toast, Alert } from "react-bootstrap";
import OrderSummary from "../../../components/checkout/OrderSummary";

const RedeemEGiftShipping = (props) => {
  const bagItems = props.bagItems;

  const prices = {
    bagItemsPrice: 0.0,
    bagShippingPrice: 0.0,
    bagTaxPrice: 0.0,
    bagTotalPrice: 0.0,
  };

  return (
    <div>
      <h2>Redeem your gift.</h2>
      <Row>
        <Col xl={4} md={6}>
          <p>Please enter your shipping information.</p>
        </Col>
      </Row>
      <Row>
        <Col lg={6} xs={12}>
          <div>
            <Alert
              show={props.errorAlert.show}
              variant="danger"
              onClose={() =>
                props.setErrorAlert({ ...props.errorAlert, show: false })
              }
              dismissible
            >
              <Alert.Heading>Sorry, something went wrong.</Alert.Heading>
              <p>{props.errorAlert.message}</p>
            </Alert>
            <Toast
              className="w-100"
              onClose={() => props.setShow(false)}
              show={props.show}
              delay={5000}
              autohide
              bg="warning"
            >
              <Toast.Header>
                <strong className="me-auto">Oops!</strong>
              </Toast.Header>
              <Toast.Body>
                Please make sure the shipping address and email are correct.
              </Toast.Body>
            </Toast>
            <Card>
              <Card.Header>Shipping</Card.Header>
              <Card.Body>
                <Form onSubmit={props.shippingSubmitHandler}>
                  <Row className="mb-3">
                    <Form.Group as={Col} controlId="formGridFName">
                      <Form.Label>First Name</Form.Label>
                      <Form.Control
                        required
                        type="firstname"
                        placeholder="first"
                        value={props.shippingInfo.firstName || ""}
                        onChange={(e) =>
                          props.setShippingInfo({
                            ...props.shippingInfo,
                            firstName: e.target.value,
                          })
                        }
                      />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridLName">
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control
                        required
                        type="lastname"
                        placeholder="last"
                        value={props.shippingInfo.lastName || ""}
                        onChange={(e) =>
                          props.setShippingInfo({
                            ...props.shippingInfo,
                            lastName: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                  </Row>

                  <Row className="mb-3">
                    <Form.Group as={Col} controlId="formGridEmail">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="example@gmail.com"
                        value={props.shippingInfo.email || ""}
                        onChange={(e) =>
                          props.setShippingInfo({
                            ...props.shippingInfo,
                            email: e.target.value,
                          })
                        }
                      />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridPhone">
                      <Form.Label>Phone Number</Form.Label>
                      <Form.Control
                        type="phone"
                        placeholder="###-###-####"
                        value={props.shippingInfo.phone || ""}
                        onChange={(e) =>
                          props.setShippingInfo({
                            ...props.shippingInfo,
                            phone: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                  </Row>

                  <Form.Group className="mb-3" controlId="formGridAddress1">
                    <Form.Label>Address</Form.Label>
                    <Form.Control
                      required
                      placeholder="1234 Main St"
                      value={props.shippingInfo.addressOne || ""}
                      onChange={(e) =>
                        props.setShippingInfo({
                          ...props.shippingInfo,
                          addressOne: e.target.value,
                        })
                      }
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formGridAddress2">
                    <Form.Label>Address 2</Form.Label>
                    <Form.Control
                      placeholder="Apartment, studio, or floor"
                      value={props.shippingInfo.addressTwo || ""}
                      onChange={(e) =>
                        props.setShippingInfo({
                          ...props.shippingInfo,
                          addressTwo: e.target.value,
                        })
                      }
                    />
                  </Form.Group>

                  <Form.Group as={Col} controlId="formGridCountry">
                    <Form.Label>Country</Form.Label>
                    <Form.Control
                      required
                      placeholder="country"
                      value={props.shippingInfo.country || ""}
                      onChange={(e) =>
                        props.setShippingInfo({
                          ...props.shippingInfo,
                          country: e.target.value,
                        })
                      }
                      readOnly={true}
                    />
                  </Form.Group>

                  <Row className="mb-3">
                    <Form.Group as={Col} controlId="formGridCity">
                      <Form.Label>City</Form.Label>
                      <Form.Control
                        required
                        placeholder="city"
                        value={props.shippingInfo.city || ""}
                        onChange={(e) =>
                          props.setShippingInfo({
                            ...props.shippingInfo,
                            city: e.target.value,
                          })
                        }
                      />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridState">
                      <Form.Label>State</Form.Label>
                      <Form.Control
                        required
                        placeholder="state"
                        value={props.shippingInfo.state || ""}
                        onChange={(e) =>
                          props.setShippingInfo({
                            ...props.shippingInfo,
                            state: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                  </Row>

                  <Row>
                    <Form.Group as={Col} controlId="formGridZip">
                      <Form.Label>Zip</Form.Label>
                      <Form.Control
                        required
                        placeholder="12345"
                        value={props.shippingInfo.zip || ""}
                        onChange={(e) =>
                          props.setShippingInfo({
                            ...props.shippingInfo,
                            zip: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                  </Row>

                  <div
                    className="d-grid col-12"
                    style={{ marginTop: "8px", marginBottom: "8px" }}
                  >
                    <Button className="w-100" type="submit">
                      Continue
                    </Button>
                  </div>
                </Form>
              </Card.Body>
            </Card>
          </div>
        </Col>
        <Col lg={6} xs={12} style={{ marginBottom: "8px" }}>
          <OrderSummary bagItems={bagItems} prices={prices} />
        </Col>
      </Row>
    </div>
  );
};

export default RedeemEGiftShipping;
