import React, { useState } from "react";
import { connect } from "react-redux";
import { saveBagCustomerEmail } from "../../redux/bag/bagActions";
import { Card, Button, Form } from "react-bootstrap";
import Toast from "react-bootstrap/Toast";

const EmailCard = (props) => {
  const [email, setEmail] = useState(props.bagCustomerEmail);
  const [show, setShow] = useState(false);

  const emailOnSubmitHandler = () => {
    // eslint-disable-next-line
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (email.match(mailformat)) {
      setShow(false);
      props.saveBagCustomerEmail(email);
      try {
        const body = JSON.stringify({
          customerEmail: email,
          list: "purchaser",
        });
        fetch("/api/klaviyo/createContact", {
          method: "post",
          headers: {
            "content-type": "application/json",
          },
          body: body,
        })
          .then((res) => console.log(res))
          .catch((err) => console.error("error:" + err));
      } catch (error) {
        console.log(error.message);
      }
      props.submitEmail();
    } else {
      setShow(true);
    }
  };

  return (
    <>
      <Toast
        className="w-100"
        onClose={() => setShow(false)}
        show={show}
        delay={5000}
        autohide
        bg="warning"
      >
        <Toast.Header>
          <strong className="me-auto">Oops!</strong>
        </Toast.Header>
        <Toast.Body>Please enter a correct email.</Toast.Body>
      </Toast>

      <Card>
        <Card.Header>
          Your Email
          {props.checkoutProcess.emailSubmitted && (
            <Button
              variant="link"
              style={{ float: "right" }}
              onClick={() => props.editEmail()}
            >
              Edit
            </Button>
          )}
        </Card.Header>
        <Card.Body>
          <Form>
            <Form.Group className="mb-3" controlId="checkoutEmailForm">
              <Form.Control
                type="email"
                placeholder="email"
                value={email || ""}
                onChange={(e) => setEmail(e.target.value)}
                readOnly={props.checkoutProcess.emailSubmitted}
              />
              <Form.Text className="text-muted">
                You'll receive receipts and notifications at this email address.
              </Form.Text>
            </Form.Group>
          </Form>
          {props.checkoutProcess.editEmail && (
            <div className="d-grid col-12" style={{ marginBottom: "8px" }}>
              <Button
                className="w-100"
                onClick={() => {
                  emailOnSubmitHandler();
                }}
              >
                Continue
              </Button>
            </div>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    bagCustomerEmail: state.bag.bagCustomerEmail,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveBagCustomerEmail: (email) => dispatch(saveBagCustomerEmail(email)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailCard);
