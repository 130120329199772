import {
  BAG_ADD_BRA,
  BAG_ADD_GIFT,
  BAG_REMOVE_ITEM,
  BAG_SAVE_SHIPPING_INFO,
  BAG_SAVE_CUSTOMER_EMAIL,
  SET_BAG_TO_EMPTY_STATE,
  BAG_APPLY_PROMO_CODE,
} from "./bagTypes";

// Direct Purchses
export const addBraToBag = (chosenBra) => {
  return {
    type: BAG_ADD_BRA,
    // Need: ItemId (zoho), number of (Avalara), price (Avalara)
    // Optional: Name (zoho, avalara)
    payload: {
      braName: chosenBra.productName,
      braPrice: chosenBra.productPrice,
      braImage: chosenBra.productImage,
      selectedItemId: chosenBra.selectedItemId,
      quantity: chosenBra.quantity,
      braColor: chosenBra.productColor,
      braSize: chosenBra.productSize,
      whenAdded: chosenBra.whenAdded,
      purchaseType: chosenBra.purchaseType,
    },
  };
};

export const addGiftToBag = (giftPackage) => {
  return {
    type: BAG_ADD_GIFT,
    payload: {
      items: giftPackage.items,
      eCardSrc: giftPackage.eCardSrc,
      senderName: giftPackage.senderName,
      recipientName: giftPackage.recipientName,
      recipientEmail: giftPackage.recipientEmail,
      message: giftPackage.message,
      deliveryMethod: giftPackage.deliveryMethod,
      whenAdded: giftPackage.whenAdded,
      packagePrice: giftPackage.packagePrice,
      purchaseType: giftPackage.purchaseType,
    },
  };
};

export const removeFromBag = (whenAdded, purchaseType) => {
  return {
    type: BAG_REMOVE_ITEM,
    payload: { whenAdded: whenAdded, purchaseType: purchaseType },
  };
};

export const saveBagShippingInfo = (shippingInfo) => {
  return {
    type: BAG_SAVE_SHIPPING_INFO,
    payload: shippingInfo,
  };
};

export const saveBagCustomerEmail = (email) => {
  return {
    type: BAG_SAVE_CUSTOMER_EMAIL,
    payload: email,
  };
};

export const setBagToEmptyState = () => {
  return {
    type: SET_BAG_TO_EMPTY_STATE,
  };
};

export const applyPromoCode = (percent) => {
  return {
    type: BAG_APPLY_PROMO_CODE,
    payload: percent,
  };
};

// Sending a gift

// Redeeming a gift
