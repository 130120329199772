import React from "react";
import { Spinner, Row } from "react-bootstrap";

const Loader = (props) => {
  return (
    <div
      style={{
        alignContent: "center",
        textAlign: "center",
      }}
    >
      <Row>
        <Spinner
          animation="border"
          role="status"
          style={{
            width: "50px",
            height: "50px",
            margin: "auto",
          }}
        />
      </Row>
      <Row>
        <p>{props.message}</p>
      </Row>
    </div>
  );
};

export default Loader;
