import React from "react";

const SizeRadioGroup = (props) => {
  return (
    <div className="sizes" style={{ marginBottom: "24px" }}>
      <label>
        <input
          type="radio"
          name="size"
          value="S/M"
          onClick={(e) => props.onItemSelectedHandler(e)}
        />
        <span className="swatch" style={{ lineHeight: "36px" }}>
          <p
            style={{
              marginBottom: "0px",
              textAlign: "center",
              height: "100%",
            }}
          >
            S/M
          </p>
        </span>
      </label>
      <label>
        <input
          type="radio"
          name="size"
          value="M/L"
          onClick={(e) => props.onItemSelectedHandler(e)}
        />
        <span className="swatch" style={{ lineHeight: "36px" }}>
          <p
            style={{
              marginBottom: "0px",
              textAlign: "center",
              height: "100%",
            }}
          >
            M/L
          </p>
        </span>
      </label>
      <label>
        <input
          type="radio"
          name="size"
          value="L/XL"
          onClick={(e) => props.onItemSelectedHandler(e)}
        />
        <span className="swatch" style={{ lineHeight: "36px", width: "48px" }}>
          <p
            style={{
              marginBottom: "0px",
              textAlign: "center",
              height: "100%",
            }}
          >
            L/XL
          </p>
        </span>
      </label>
      <label>
        <input
          type="radio"
          name="size"
          value="XL/2X"
          onClick={(e) => props.onItemSelectedHandler(e)}
        />
        <span className="swatch" style={{ lineHeight: "36px", width: "62px" }}>
          <p
            style={{
              marginBottom: "0px",
              textAlign: "center",
              height: "100%",
            }}
          >
            XL/2X
          </p>
        </span>
      </label>
    </div>
  );
};

export default SizeRadioGroup;
