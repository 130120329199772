import { ADD_REDEMPTION_LINKS } from "./redeemLinksTypes";

const initialState = { redemptionLinks: [] };

const redemptionLinksReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_REDEMPTION_LINKS:
      const redemptionLinks = action.payload;
      return {
        redemptionLinks,
      };
    default:
      return state;
  }
};

export default redemptionLinksReducer;
