import {
  GET_GIFT_ITEMS_INFO_REQUEST,
  GET_GIFT_ITEMS_INFO_SUCCESS,
  GET_GIFT_ITEMS_INFO_FAIL,
} from "./giftItemsTypes";
import axios from "axios";

export const getGiftItemInfo = () => async (dispatch) => {
  try {
    dispatch({ type: GET_GIFT_ITEMS_INFO_REQUEST });

    const { data } = await axios.get("/api/gifting/items");
    dispatch({
      type: GET_GIFT_ITEMS_INFO_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_GIFT_ITEMS_INFO_FAIL,
      payload: error.message,
    });
  }
};
