import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import GiftDetails from "./GiftDetails";
import SelectPackage from "./SelectPackage";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepButton from "@mui/material/StepButton";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/system";
import { getGiftItemInfo } from "../../../redux/giftItems/giftItemsActions";
import { connect } from "react-redux";
import Loader from "../../../components/Loader";
import Message from "../../../components/Message";

import { addGiftToBag } from "../../../redux";

const SendAGift = (props) => {
  const giftItemsInfoLoaded = useRef(false);
  const { getGiftItemInfo } = props;

  useEffect(() => {
    if (!giftItemsInfoLoaded.current) {
      getGiftItemInfo();
      giftItemsInfoLoaded.current = true;
    }
  });
  // Gift State
  const [items, setItems] = useState([]);

  // Stepper Component
  const theme = createTheme({
    palette: {
      primary: {
        main: "#221551",
      },
      secondary: {
        main: "#833177",
      },
    },
    typography: {
      fontFamily: "Josefin Sans, sans-serif",
    },
  });
  const [activeStep, setActiveStep] = useState(0);

  const [itemsSelected, setItemsSelected] = useState(false);

  const selectPackageOnClickHandler = (selectedItems) => {
    setItems(selectedItems);
    setItemsSelected(true);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    if (itemsSelected) {
      setItemsSelected(false);
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  return (
    <>
      <Helmet>
        <title>Gifts for Women | Reyz</title>
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content="Simple to send and meaningful wellness gifts curated for women with sensitive skin and needs from post surgery recovery. no size information or address needed."
        />
      </Helmet>
      <div className="justify-content-center mb-4 col-lg-4 offset-lg-4">
        <ThemeProvider theme={theme}>
          <Stepper className="muiStep" activeStep={activeStep}>
            <Step key="Select Package Items">
              <StepButton onClick={handleBack}>Select Package Items</StepButton>
            </Step>
            <Step key="Enter Gift Info">
              <StepLabel>Enter Gift Info</StepLabel>
            </Step>
          </Stepper>
        </ThemeProvider>
      </div>
      {props.loading ? (
        <Loader message="loading gift package items!" />
      ) : props.error ? (
        <Message variant="danger">{props.error}</Message>
      ) : (
        <>
          {itemsSelected ? (
            <GiftDetails
              history={props.history}
              items={items}
              addGiftToBag={props.addGiftToBag}
            />
          ) : (
            <SelectPackage
              selectPackageOnClickHandler={selectPackageOnClickHandler}
              giftItemsInfo={props.giftItemsInfo}
            />
          )}
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    giftItemsInfo: state.giftItems.giftItemsInfo,
    loading: state.giftItems.loading,
    error: state.giftItems.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getGiftItemInfo: () => dispatch(getGiftItemInfo()),
    addGiftToBag: (giftPackage) => dispatch(addGiftToBag(giftPackage)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SendAGift);
