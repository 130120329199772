import React from "react";
import Helmet from "react-helmet";

import michChronDec21 from "../../assets/images/in-the-news/mich-chron-dec.webp";
import michCronMarch22 from "../../assets/images/in-the-news/michcron-march22.webp";
import vwJuneSpotlight from "../../assets/images/in-the-news/vw-june-spotlight.webp";

import comfortable from "../../assets/images/in-the-news/comfortable.webp";
import tedxdetroit from "../../assets/images/in-the-news/tedxdetroit.webp";
import wbencpitch from "../../assets/images/in-the-news/wbencpitch.webp";

const News = ({ imgSrc, articleHeadline, articleDate, articleLink }) => {
  return (
    <div style={{ marginBottom: "32px" }}>
      <h3 style={{ textAlign: "center" }}>{articleHeadline}</h3>
      <p style={{ textAlign: "center" }}>{articleDate}</p>
      <img
        style={{
          horizontalAlign: "center",
          height: "200px",
          display: "block",
          margin: "0 auto",
        }}
        src={imgSrc}
      />
      <a
        style={{ color: "#221551", display: "block", textAlign: "center" }}
        target="_blank"
        rel="noopener noreferrer"
        href={articleLink}
      >
        View Here
      </a>
    </div>
  );
};

const InTheNews = () => {
  return (
    <>
      <Helmet>
        <title>In the News | Reyz</title>
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content="The word is out about Reyz. See where Reyz and our founder, Tamika Mayes, have been featured in the press. Get the latest news! Learn about new products, upcoming events & collaborations, and behind-the-scenes stories about Reyz."
        />
      </Helmet>
      <div style={{ textAlign: "center" }}>
        <h1>In the News</h1>
        <News
          imgSrc={wbencpitch}
          articleHeadline="WBENCPitch Finalist"
          articleDate="Nov 2022"
          articleLink="https://www.greatlakeswbc.org/news/tamika-mayes-reyz-presents-at-tedx-detroit/"
        />
        <News
          imgSrc={tedxdetroit}
          articleHeadline="TED X Detroit"
          articleDate="Oct 2022"
          articleLink="https://www.clickondetroit.com/video/local-news/2022/10/27/innovation-on-display-at-14th-annual-tedxdetroit-conference/"
        />
        <News
          imgSrc={vwJuneSpotlight}
          articleHeadline="VentureWell Juneteenth Spotlight"
          articleDate="June 2022"
          articleLink="https://www.linkedin.com/posts/venturewell_juneteenth-blackowned-buyblack-activity-6947919301018349568-yvns/?utm_source=linkedin_share&utm_medium=member_desktop_web"
        />
        <News
          imgSrc={michCronMarch22}
          articleHeadline="Michigan Chronicle"
          articleDate="March 2022"
          articleLink="https://michiganchronicle.com/2022/03/30/the-truth-about-bras/"
        />
        <News
          imgSrc={michChronDec21}
          articleHeadline="Luxury Bra Line Launches in Eastern Market"
          articleDate="Dec 2021"
          articleLink="https://michiganchronicle.com/2021/12/20/luxury-bra-line-launches-in-eastern-market/"
        />
        <News
          imgSrc={comfortable}
          articleHeadline="Comfortable Bra for Breast Cancer Patients"
          articleDate="Nov 2021"
          articleLink="http://bcsurgerystories.com/?p=1792"
        />
      </div>
    </>
  );
};

export default InTheNews;
